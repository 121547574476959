import { useEffect, useState } from "react";
import "../../components/SalesEnquiry/SalesEnquiry.css";
import "./InvestorRelations.css";
import "./style.scss";
import Dash from "../../components/Dash/Dash";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Joi from "joi-browser";

import {
  modal2019,
  modal2020,
  modal2021,
  InvestorBgImg,
  InvestorFgImg,
  InvestorOverviewImg,
  InvestorStakeholderImg,
  pdf,
  pdfsecond,
  pdfthird,
  pdffourth,
  pdffifth,
  modalCross,
  coloredArrow,
  pdf10,
  pdf11,
  pdf12,
  pdf13,
  pdf14,
  pdf15,
  pdf16,
  pdf17,
  pdf18,
  pdf19,
  pdf20,
  pdf21,
  pdf22,
  pdf23,
  pdf24,
  pdf25,
  pdf26,
  pdf27,
  pdf28,
  blankpdf,
  pdf29,
  modal2022,
  sppl1,
  pdf30,
  vipl2,
  pdf31,
  csr1,
  csr2,
  csr3,
  modal2023,
  pdf32,
  sppl2,
  blcPdf2023,
  sppl3,
  vipl1,
} from "../../images";

//swiper imports
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import { Link } from "react-router-dom";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const InvestorRelations = (props) => {
  const [shouldModalOpen, setShouldModalOpen] = useState(false);
  const [shouldModalOpen2, setShouldModalOpen2] = useState(false);
  const [shouldModalOpen3, setShouldModalOpen3] = useState(false);
  const [ShouldModalOpen4, setShouldModalOpen4] = useState(false);
  const [ShouldModalOpen5, setShouldModalOpen5] = useState(false);
  const [ShouldModalOpen6, setShouldModalOpen6] = useState(false);
  const [ShouldModalOpen7, setShouldModalOpen7] = useState(false);
  const [isInvestorFormOpen, setIsInvestorFormOpen] = useState(false);
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = new URLSearchParams(props.location.search);
    const token = query.get("investor-relation");
    if (token === "form") {
      setIsInvestorFormOpen(true);
    }
  }, []);

  const schema = Joi.object({
    name: Joi.string().required(),
    email: Joi.string().email().required(),
    oraganization: Joi.string().allow(""),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = Joi.validate(data, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }
    console.log("form submit");
    setLoading(true);
    // let formData = new FormData();
    // for (const [key, data] of Object.entries(_.omit(value, ["image"]))) {
    //   formData.append(key, data || "");
    // }
    // formData.append("image", value.image.file);
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/investor`,
      values
    );
    console.log(data);

    if (data.status === 200) {
      //  file downloading
      // const link = document.createElement("a");
      // link.href = blankpdf;
      // // link.setAttribute("target", `_blank`);
      // link.setAttribute("download", `blank.pdf`);

      // // Append to html link element page
      // document.body.appendChild(link);

      // // Start download
      // link.click();

      // // Clean up and remove the link
      // link.parentNode.removeChild(link);
      setLoading(false);
      setIsInvestorFormOpen(false);
      setValues({});
      toast.success("Thank you, Your message has been received.", {
        autoClose: 3000,
      });
      return;
    }

    if (data.status >= 400 || data.status <= 499) {
      toast.error(data.message, {
        autoClose: 5000,
      });
      setLoading(false);

      return;
    }

    // if (data) {
    //   notification["success"]({
    //     message: `${moduleName} Added Successfully`,
    //   });
    //   setloading(false);
    //   // form.resetFields();
    //   handleClose();
    // }
  };

  const handleChange = (e) => {
    // console.log(e.target.value);
    // let data = values;

    // data[e.target.name] = e.target.value;
    // setValues(data);

    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/investor-relations"
        metaTitle="Investor Relations | Sanathan Textiles"
        metaDesc="Access the latest financial information and updates with Sanathan Textiles' investor relations. Explore our setup, reports, and performance insights for informed investment decisions."
      />
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <section className="investor_relations">
        <div className="wrapper">
          <div class="breadcrumb_styles">
            <Link to="/" className="breadcrumb_link">
              Home
            </Link>{" "}
            {">"} Investor Relations
          </div>
          <div className="left_section">
            <h1 className="hollow_title">Investor Relations</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            {/* <h3></h3> */}
            {/* <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p> */}
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={InvestorBgImg} alt="sanathan img" />
            </figure>
            <figure className="fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
          </div>
          {/* mobile section for image*/}
          <div className="mobile_section">
            <figure className="mob_bg_img">
              <img
                className="mob_img1"
                src={InvestorBgImg}
                alt="sanathan img"
              />
            </figure>
            <figure className="mob_fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>{" "}
      <section className="overview_financialinfo">
        <div className="wrapper">
          <h3 className="fi_heading">Financial Information</h3>
          <div className="flexbox">
            <div className="pdf_box">
              <div className="num1" onClick={() => setShouldModalOpen(true)}>
                <div className="hollow_title">1</div>
                <h5 className="fi_numsdesc">Sanathan Textiles Limited</h5>
              </div>
              <div className="btn_wrapper">
                <a
                  className="sanathan_btn"
                  onClick={() => setShouldModalOpen(true)}
                >
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
              </div>
            </div>
            <div className="pdf_box">
              <div className="num2">
                <div className="hollow_title">2</div>
                <h5 className="fi_numsdesc">
                  Universal Texturisers Private Limited (Subsidiary)
                </h5>
              </div>
              <div className="btn_wrapper">
                {/* <a className="sanathan_btn" href={pdfsecond} download>
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a> */}
                <a
                  className="sanathan_btn"
                  onClick={() => setShouldModalOpen3(true)}
                >
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
              </div>
            </div>
            <div className="pdf_box">
              <div className="num4" onClick={() => setShouldModalOpen4(true)}>
                <div className="hollow_title">3</div>
                <h5 className="fi_numsdesc">
                  Sanathan Polycot Private Limited (Subsidiary)
                </h5>
              </div>
              <div className="btn_wrapper">
                <a
                  className="sanathan_btn"
                  onClick={() => setShouldModalOpen4(true)}
                >
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
              </div>
            </div>
            <div className="pdf_box">
              {/* <div className="num3">
                <div className="hollow_title">3</div>
                <h5 className="fi_numsdesc">
                  Vajubhai Investments Private Limited (Group Company)
                </h5>
              </div>
               <div className="btn_wrapper">
                <a className="sanathan_btn" href={pdfthird} download>
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
              </div> */}
              <div className="num3" onClick={() => setShouldModalOpen5(true)}>
                <div className="hollow_title">4</div>
                <h5 className="fi_numsdesc">
                  Vajubhai Investments Private Limited (Group Company)
                </h5>
              </div>
              <div className="btn_wrapper">
                <a
                  className="sanathan_btn"
                  onClick={() => setShouldModalOpen5(true)}
                >
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
              </div>
            </div>
            {/* <div className="pdf_box">
              <div className="num4" onClick={() => setShouldModalOpen4(true)}>
                <div className="hollow_title">4</div>
                <h5 className="fi_numsdesc">
                  Sanathan Polycot Private Limited
                </h5>
              </div>
              <div className="btn_wrapper">
                <a
                  className="sanathan_btn"
                  onClick={() => setShouldModalOpen4(true)}
                >
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="other_information">
        <div className="wrapper">
          <h3 className="fi_heading">Other Information</h3>
          <div className="flexbox flexbox_new">
            <div className="pdf_box">
              <div className="num3">
                <div className="hollow_title">1</div>
                <h5 className="fi_numsdesc">
                  Materiality Policy and related information
                </h5>
              </div>
              <div className="btn_wrapper">
                <a className="sanathan_btn" href={pdffourth} download>
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
              </div>
            </div>
            <div className="pdf_box pdf_box_2">
              <div className="num3">
                <div className="hollow_title">2</div>
                <h5 className="fi_numsdesc">
                  CRISIL Report on Yarn Manufacturing in India
                </h5>
              </div>
              <div className="btn_wrapper">
                <a className="sanathan_btn" href={pdffifth} download>
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
              </div>
            </div>
            <div className="pdf_box pdf_box_3">
              <div className="num1" onClick={() => setShouldModalOpen2(true)}>
                <div className="hollow_title">3</div>
                <h5 className="fi_numsdesc">Codes and Policies</h5>
              </div>
              <div className="btn_wrapper">
                <a
                  className="sanathan_btn"
                  onClick={() => setShouldModalOpen2(true)}
                >
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
              </div>
            </div>
            <div className="pdf_box pdf_box_4">
              <div className="num1">
                <div className="hollow_title">4</div>
                <h5 className="fi_numsdesc">Investor Information</h5>
              </div>
              <div className="btn_wrapper">
                <a
                  className="sanathan_btn"
                  onClick={() => setIsInvestorFormOpen(true)}
                >
                  <span className="download_btn_text">FIll the form</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        {isInvestorFormOpen && (
          <>
            <ToastContainer
              position="top-right"
              autoClose={10000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
            />
            <section className="popup_form_wrapper_sales">
              <div className="salesEnquiry">
                <div className="close_button">
                  <button
                    type="button"
                    onClick={() => setIsInvestorFormOpen(false)}
                    className="btn-close btn-close-sales"
                  >
                    <span class="icon-cross"></span>
                    {/* <span class="visually-hidden">Close</span> */}
                    {/* <span aria-hidden="true">&times;</span> */}
                  </button>
                </div>
                <div className="wrapper">
                  <h3>Fill the form</h3>
                  <p>
                    To access Investor Information please provide following
                    information
                  </p>
                  <div className="form_wrapper">
                    <form onSubmit={handleSubmit} id="investor_form">
                      <div className="form_input_wrapper form-group">
                        <TextField
                          type="text"
                          name="name"
                          label="Full Name*"
                          variant="standard"
                          value={values.name}
                          onChange={handleChange}
                          // required
                        />
                        <div className="err">{formError.name}</div>
                      </div>
                      <div className="form_input_wrapper form-group">
                        <TextField
                          type="text"
                          name="email"
                          label="Email Id*"
                          variant="standard"
                          value={values.email}
                          onChange={handleChange}
                          // required
                        />
                        <div className="err">{formError.email}</div>
                      </div>

                      <div className="form_input_wrapper form-group message_wrapper">
                        <TextField
                          type="text"
                          name="organization"
                          label="Organisation"
                          variant="standard"
                          value={values.organization}
                          onChange={handleChange}
                          // required
                        />
                        <div className="err">{formError.organization}</div>
                      </div>
                      <div className="submit_button_container">
                        <button
                          type="submit"
                          className="sanathan_btn sanathan_actual_btn"
                          id="investor_form_submit_btn"
                        >
                          <span className="download_btn_text">
                            {loading ? "Submitting.." : "Submit"}
                          </span>
                          <img
                            src={coloredArrow}
                            className="btn_arrow"
                            alt="sanathan button arrow"
                          />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </section>
      {/* <section className="overview_otherinfo">
        <div className="wrapper">
          <div>
            <a href={}></a>
            <a href={}></a>
          </div>
          <h3 className="other_heading">Other Information</h3>
          <h5 className="other_desc">
            List of outstanding over-dues to material creditors as of September
            30, 2021
          </h5>
          <table>
            <tr className="onlytr">
              <td className="left_td">
                <strong>Name of Party</strong>
              </td>
              <td className="right_td">
                <strong>Amount in million (Rs.)</strong>
              </td>
            </tr>
            <tr>
              <td className="left_td">ITOCHU CORPORATION</td>
              <td className="right_td">571.06</td>
            </tr>
            <tr>
              <td className="left_td">RELIANCE INDUSTRIES LTD.</td>
              <td className="right_td">417.54</td>
            </tr>
            <tr>
              <td className="left_td">EMIRATES L/C DISCOUNTING (RE)</td>
              <td className="right_td">398.15</td>
            </tr>
            <tr>
              <td className="left_td">KOTAK MAHINDRA L/C DISCOUNTING</td>
              <td className="right_td">366.73</td>
            </tr>
            <tr>
              <td className="left_td">SBI(IOCL)L/C DISCOUNTING</td>
              <td className="right_td">264.02</td>
            </tr>
            <tr>
              <td className="left_td">ORIENTAL PETROCHEMICAL (TAIWAN)</td>
              <td className="right_td">212.40</td>
            </tr>
          </table>
        </div>
      </section> */}
      {shouldModalOpen && (
        <div className="pdf_modal_wrapper">
          <div className="pdf_modal_content">
            <img
              src={modalCross}
              alt="sanathan img"
              className="pdf_modal_cross"
              onClick={() => setShouldModalOpen(false)}
            />
            <h3>Sanathan Textiles Limited</h3>
            <div className="pdf_flex">
              {/* <div className="pdf_content">
                <div className="hollow_title">1</div>
                <h5 className="fi_numsdesc">STL Financials 2019</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={modal2019} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div> */}
              <div className="pdf_content">
                <div className="hollow_title">1</div>
                <h5 className="fi_numsdesc">STL Financials Dec 2023</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={modal2020} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content">
                <div className="hollow_title">2</div>
                <h5 className="fi_numsdesc">STL Financials 2023</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={modal2021} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content">
                <div className="hollow_title">3</div>
                <h5 className="fi_numsdesc">STL Financials 2022</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={modal2022} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content">
                <div className="hollow_title">4</div>
                <h5 className="fi_numsdesc">STL Financials 2021</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={modal2023} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {shouldModalOpen3 && (
        <div className="pdf_modal_wrapper">
          <div className="pdf_modal_content">
            <img
              src={modalCross}
              alt="sanathan img"
              className="pdf_modal_cross"
              onClick={() => setShouldModalOpen3(false)}
            />
            <h3>Universal Texturisers Private Limited (Subsidiary)</h3>
            <div className="pdf_flex">
              {/* <div className="pdf_content">
                <div className="hollow_title">1</div>
                <h5 className="fi_numsdesc">UTPL Financials 2019</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf24} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div> */}
              <div className="pdf_content">
                <div className="hollow_title">1</div>
                <h5 className="fi_numsdesc">UTPL Financials Dec 2023</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf25} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content">
                <div className="hollow_title">2</div>
                <h5 className="fi_numsdesc">UTPL Financials 2023</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf26} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content">
                <div className="hollow_title">3</div>
                <h5 className="fi_numsdesc">UTPL Financials 2022</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf30} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content">
                <div className="hollow_title">4</div>
                <h5 className="fi_numsdesc">UTPL Financials 2021</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf32} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {shouldModalOpen2 && (
        <div className="pdf_modal_wrapper2">
          <div className="products_popup">
            <img
              src={modalCross}
              alt="sanathan img"
              className="pdf_modal_cross2"
              onClick={() => setShouldModalOpen2(false)}
            />
            <h3>Codes and Policies</h3>
            <div className="pdf_flex2">
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">Board Evaluation Policy</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf11} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">Board Level Committees</h5>
                <div className="btn_wrapper">
                  {/* <a className="sanathan_btn" href={pdf12} download> */}
                  <a className="sanathan_btn" href={blcPdf2023} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              {/* <div className="pdf_content2">
                <h5 className="fi_numsdesc">
                  Board Level Committees and their members
                </h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf13} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div> */}
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">Archival Policy</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf10} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">
                COC for Directors and Senior Management
                </h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf14} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">Material Subsidiaries</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf15} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">Materiality Policy</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf16} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">
                  Nomination and Remuneration Policy
                </h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf17} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">Policy on Board Diversity</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf18} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">
                  Policy with respect to obligations of directors and senior
                  management
                </h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf19} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">
                  Related Party Transaction Policy
                </h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf29} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">Risk Management Policy</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf21} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">Succession Policy</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf22} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">Vigil Mechanism</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf23} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">CSR Policy</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdf27} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">
                  Familiarization Programme for Independent Directors
                </h5>
                <div className="btn_wrapper">
                  {/* <a className="sanathan_btn" href={pdf28} download> */}
                  <a className="sanathan_btn" href={pdf31} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">
                  CSR Annual Action Plan <br /> (FY 22-23)
                </h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={csr1} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                <h5 className="fi_numsdesc">
                  CSR Annual Action Plan <br /> (FY 22-23 - Ongoing)
                </h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={csr2} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content2">
                {/* <h5 className="fi_numsdesc">CSR Annual Action Plan</h5>
                <div className="btn_wrapper">
                <a
                  className="sanathan_btn" href={csr2} download>
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {ShouldModalOpen6 && (
          <div className="pdf_modal_wrapper">
          <div className="pdf_modal_content">
            <img
              src={modalCross}
              alt="sanathan img"
              className="pdf_modal_cross"
              onClick={() => setShouldModalOpen6(false)}
            />
            <h3>CSR Annual Action Plan</h3>
            <div className="pdf_flex">
              <div className="pdf_content">
                <div className="hollow_title">1</div>
                <h5 className="fi_numsdesc"> CSR Annual Action Plan (FY 22-23)</h5>
                <div className="btn_wrapper">
                <a
                  className="sanathan_btn"
                  onClick={() => setShouldModalOpen7(true)}
                >
                  <span className="download_btn_text">download</span>
                  <img
                    src={coloredArrow}
                    className="btn_arrow"
                    alt="sanathan img"
                  />
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        )} */}
      {/* {ShouldModalOpen7 && (
          <div className="pdf_modal_wrapper">
          <div className="pdf_modal_content">
            <img
              src={modalCross}
              alt="sanathan img"
              className="pdf_modal_cross"
              onClick={() => setShouldModalOpen7(false)}
            />
            <h3>CSR Annual Action Plan 2023</h3>
            <div className="pdf_flex">
              <div className="pdf_content">
                <div className="hollow_title">1</div>
                <h5 className="fi_numsdesc">CSR ongoing Annual Action plan</h5>
                <div className="btn_wrapper">
                <a className="sanathan_btn" href={csr3} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        )} */}
      {ShouldModalOpen4 && (
        <div className="pdf_modal_wrapper">
          <div className="pdf_modal_content">
            <img
              src={modalCross}
              alt="sanathan img"
              className="pdf_modal_cross"
              onClick={() => setShouldModalOpen4(false)}
            />
            <h3>Sanathan Polycot Private Limited</h3>
            <div className="pdf_flex">
              <div className="pdf_content">
                <div className="hollow_title">1</div>
                <h5 className="fi_numsdesc">SPPL Financials Dec 2023</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={sppl2} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content">
                <div className="hollow_title">2</div>
                <h5 className="fi_numsdesc">SPPL Financials 2023</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={sppl2} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content">
                <div className="hollow_title">3</div>
                <h5 className="fi_numsdesc">SPPL Financials 2022</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={sppl3} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShouldModalOpen5 && (
        <div className="pdf_modal_wrapper">
          <div className="pdf_modal_content">
            <img
              src={modalCross}
              alt="sanathan img"
              className="pdf_modal_cross"
              onClick={() => setShouldModalOpen5(false)}
            />
            <h3>Vajubhai Investments Private Limited (Group Company)</h3>
            <div className="pdf_flex">
              <div className="pdf_content">
                <div className="hollow_title">1</div>
                <h5 className="fi_numsdesc">VIPL Financials 2023</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={vipl1} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content">
                <div className="hollow_title">2</div>
                <h5 className="fi_numsdesc">VIPL Financials 2022</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={vipl2} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
              <div className="pdf_content">
                <div className="hollow_title">3</div>
                <h5 className="fi_numsdesc">VIPL Financials 2021</h5>
                <div className="btn_wrapper">
                  <a className="sanathan_btn" href={pdfthird} download>
                    <span className="download_btn_text">download</span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan img"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvestorRelations;
