import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  facebook,
  gallery1,
  moreblog1,
  moreblog2,
  moreblog3,
  playicon,
  whatsapp,
  twitterblog,
  copy,
  blogimg10,
} from "../../images";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Link } from "react-router-dom";
//utils
import { reduceDescSize } from "../../helpers/utils";

import "./BlogInner10.scss";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const BlogInner10 = () => {
  const { width } = useWindowDimensions();
  const [copied, setCopied] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const blogDateArr = [
    {
      date: "June 2, 2022",
      category: "Lorem Ipsum",
      company: "Sanathan",
    },
  ];

  const blogDescArr = [
    {
      id: 1,
      heading:
        "Cationic Dyeable Polyester Yarn An Absolute Game Changer in Fancy + Innovative Fabrics",
      desc1: `
      In this rapidly surging industry, an epic innovation is CDP (Cationic Dyeable Polyester) yarn, a versatile and dynamic yarn that has transformed the way fabrics are produced and dyed. CDP yarn offers numerous benefits in terms of cost-effectiveness, dyeing efficiency, and flexibility, making it an ideal choice for manufacturers and designers alike.
      <br/><br/>
      This blog will explore the features, dyeing process, and applications of CDP yarn, highlighting why it stands out in the textile industry.
      <br/><br/>
      <strong>What is CDP Yarn?</strong>
      <br/><br/>
      Cationic Dyeable Polyester (CDP) yarn is a specialized type of polyester adapted for better dyeing properties specifically with cationic dyes. Normally, polyester can only be dyed at raised temperatures and with disperse dyes, restricting blend opportunities with other fibers like wool or elastane. With CDP yarn, however, dyeing can be accomplished at cooler temperatures under highly advantageous blends and allows for production of bright, high-luminary colors.
      <br/><br/>
      <strong>Key Features of CDP Yarn :</strong>
      <br/><br/>
      <strong>Cost-effective Dyeing Process:</strong>
      <br/><br/>
      Appraisals comprise the benefits available from CDP yarn. It reduces dyeing costs only by being dyed within lower temperature ranges, in terms of 98°C to 120°C, which eventually saves energy and water in such processes. Consequently, it becomes a sustainable yarn that improves savings while adding reduced burdens on planet earth from textile production.
      <br/><br/>
      <strong>Efficient Dyeing with Cationic Dyes:</strong>
      <br/><br/>
      As a result, the dyeing properties of CDP yarn are excellent with cationic dyes. When using cationic dyes, this dyeing process provides very good bath exhaustion ensuring that little dye remains in the bath after dyeing, improving reduction of waste as well as increasing dyeing speed with shorter production times.
      <br/><br/>
      <strong>Versatility in Dyeing: </strong>
      <br/><br/>
      While CDP yarn is primarily dyed with cationic dyes, it can also be colored using dispersed dyes at temperatures up to 120°C. This versatility opens up possibilities for combining CDP yarn with other fibers like wool and elastane (Lycra), which cannot be dyed at high temperatures. The ability to mix CDP yarn with other fibers enhances its flexibility for creating dual-tone and multi-color effects, a desirable feature in the fashion and textile industries.
      <br/><br/>
      <strong>High Luminosity and Low Pilling:</strong>
      When dyed with cationic dyes, CDP yarn delivers high luminosity in the final product, creating vibrant and striking colors that stand out. Additionally, the yarn has low pilling properties, ensuring that fabrics made from CDP yarn remain smooth and durable, even after repeated use and washing.
      <br/><br/>
      <strong>Dyeing Process with CDP Yarn</strong>
      <br/><br/>
      The dyeing process with CDP yarn offers several advantages that make it a preferred choice for textile manufacturers:
      <br/><br/>
      <strong>High Dyeing Power:</strong> Both disperse dyes and cationic dyes deliver strong dye uptake on CDP yarn, ensuring vibrant and uniform shades.
      <br/><br/>
      <strong>Faster Dyeing Process:</strong> The dyeing process is shorter compared to traditional polyester, thanks to the yarn’s ability to absorb dyes quickly. This results in faster production cycles and lower energy consumption.
      <br/><br/>
      <strong>Lower Dyeing Costs:</strong> The use of lower temperatures for cationic dyes helps reduce overall dyeing costs, as it requires less energy to achieve desired results. Additionally, the absence of subsequent reductive cleaning further cuts down on time and resource use.
      <br/><br/>
      <strong>Excellent Resistance:</strong> Fabrics dyed with CDP yarn demonstrate excellent resistance to wetness and sublimation, ensuring that colors remain vibrant and intact, even in challenging conditions.
      <br/><br/>
      <strong>Dual-tone and Multi-color Effects:</strong> The ability to mix CDP yarn with other fibers allows for the creation of unique fashion shades and dual-tone effects, making it a popular choice for brands and designers looking to experiment with new looks.
      <br/><br/>
      <strong>Applications of CDP Yarn</strong>
      <br/><br/>
      Given its wide range of benefits, CDP yarn is used in various textile applications, including:
      <br/><br/>
      <strong>Sportswear:</strong> CDP yarn is ideal for performance fabrics that require vibrant colors, durability, and flexibility. Its ability to blend with elastane makes it a popular choice for activewear and sports apparel.
      <br/><br/>
      <strong>Intimate Wear:</strong> The yarn’s softness, low pilling, and ability to hold bright colors make it perfect for intimate wear like lingerie and underwear, where comfort and aesthetics are key.
      <br/><br/>
      <strong>Casual Wear:</strong> From T-shirts to casual pants, CDP yarn provides excellent color retention and durability, making it a go-to option for casual everyday clothing.
      <br/><br/>
      <strong>Home Textiles:</strong> The high dyeing speed and cost-effectiveness of CDP yarn make it suitable for home textiles such as bed linens, curtains, and cushions, where colorfastness and durability are essential.
      <br/><br/>
      <strong>Upholstery:</strong> In furniture and upholstery, CDP yarn offers vibrant colors and robust performance, making it a reliable choice for sofas, chairs, and other interior fabrics.
      <br/><br/>
      With its versatility and durability, CDP yarn continues to redefine the textile industry. Whether it's enhancing the performance of sportswear, adding comfort to intimate wear, or providing long-lasting color to home textiles, CDP yarn proves to be an essential component in a wide range of products. 
      <br/><br/>
      <strong>Sanathan Textile: Leading the Way with CDP Yarn</strong>
      <br/><br/>
      At this point, just now, the new textile revolution flies with the Cationic Dyeable Polyester yarns offered by Sanathan Textile. It is adding value, where this company is among a few manufacturers who have in-house, fully integrated Cationic Polyester Polymerization setup, manufacturing facilities, and a variety of CDP yarn production capabilities, including Texturising CDP Yarn, FDY CDP Yarn, POY CDP, and Air Tex CDP Yarn, among many others. This is so as to meet the ever-evolving needs of customers and the industry.
      <br/><br/>
      <strong>Conclusion</strong>
      <br/><br/>
      Liberalizing the economy cost-effective dyeing processes along with versa tile and environmentally friendly to the yarn world, CDP yarn has indeed revolutionized the concept of high-quality vibrant fabrics in the <a href="https://www.sanathan.com/">textile industries</a>. From sportswear to home textiles, this dynamic yarn is truly an asset in today's production and fashion because it has different application uses and can perform a variety of functions.
      `,
    },
  ];

  const moreblogsArr = [
    {
      img: moreblog1,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog3,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
  ];

  const galleryArr = [
    {
      id: 1,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 2,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 3,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 4,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
  ];

  useEffect(() => {
    var gallery_swiper_container = new Swiper(
      ".swiper-container.gallery_swiper_container",
      {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          prevEl: ".bloginner_sec2 .left_arrow",
          nextEl: ".bloginner_sec2 .right_arrow",
        },
        breakpoints: {
          767: {
            slidesPerView: 1.6,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 2.7,
            spaceBetween: 10,
          },
        },
        // loop: true,
        // centeredSlides: true,
        // loopedSlides: thumbArr.length, //how many slides it should duplicate
      }
    );
    var moreblogs_swiper_container = new Swiper(
      ".swiper-container.moreblogs_swiper_container",
      {
        slidesPerView: 3,
        spaceBetween: 40,
        loop: false,
        navigation: {
          prevEl: ".bloginner_sec3 .left_arrow",
          nextEl: ".bloginner_sec3 .right_arrow",
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          767: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      }
    );
  });

  useEffect(() => {
    if (copied) {
      let copiedTimeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => {
        clearTimeout(copiedTimeout);
      };
    }
  }, [copied]);

  const dateList = blogDateArr.map((item) => (
    <>
      <p className="blog_date">{item.date} | </p>
      <p className="blog_category"> {item.category} </p>
      <p className="blog_company">| {item.company}</p>
    </>
  ));

  const descList = blogDescArr.map((item) => (
    <>
      <p
        className="blog_desc"
        dangerouslySetInnerHTML={{ __html: item.desc1 }}
      />
    </>
  ));

  const galleryVideoImageList = galleryArr.length
    ? galleryArr.map((item, i) => (
        <div className="swiper-slide" key={i}>
          {item.type == "video" ? (
            <>
              <div
                className="video_wrapper_div"
                onClick={() => handleImageWrapperClick(item.ytLink)}
              >
                <div className="content">
                  <img
                    src={item.image}
                    alt="Sanathan video gallery img"
                    loading="lazy"
                    className="gallery_img"
                  />
                  <div class="content-overlay"></div>
                  <img
                    src={playicon}
                    alt="Sanathan coloredArrow img"
                    loading="lazy"
                    className="playicon"
                    onClick={() => handleImageWrapperClick(item.ytLink)}
                  />
                  {/* <h2 className="galleryImageName">{item.title}</h2> */}
                </div>

                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          ) : (
            <>
              <div className="content">
                <img
                  src={item.image}
                  alt="Sanathan blog gallery img"
                  loading="lazy"
                  className="gallery_img"
                />
                <div class="content-overlay"></div>
                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          )}
        </div>
      ))
    : null;

  const moreblogsList = moreblogsArr.map((item, i) => (
    <>
      <li className="swiper-slide moreblog_detail_wrapper">
        <Link to="/blog-inner" key={i}>
          <div className="imageanimate zoom-out">
            <img
              src={item.img}
              alt="Sanathan our blog img"
              loading="lazy"
              className="moreblog_img"
            />
            {/* <div class="content-overlay"></div> */}
          </div>
          <div className="text_container">
            <h1 className="moreblog_date">{item.date}</h1>
            <div className="verticle_line"> | </div>
            <h1 className="moreblog_category"> {item.category}</h1>
            <h1 className="moreblog_name">{item.title}</h1>
            <p className="moreblog_synopsis">
              {reduceDescSize(item.desc, 100)}
            </p>
            <div className="moreblog_cta_wrapper"></div>
          </div>
        </Link>
      </li>
    </>
  ));

  const handleImageWrapperClick = (videoLink) => {
    setToggler(!toggler);
    setVidLink(videoLink);
  };

  const copyUrl = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Cationic Dyeable Polyester Yarn:A Revolution in Fancy Innovative Fabrics | Sanathan Textiles"
        metaDesc="Transform fabric innovation with cationic dyeable polyester yarn. Explore its role in creating vibrant, versatile, and sustainable fancy fabrics at Sanathan Textiles."
      />
      <section className="bloginner_sec1" data-aos="fade-in">
        <div className="wrapper">
          <img
            className="mainimg"
            src={blogimg10}
            alt="Sanathan blog inner img"
          />
          <div className="row social_icon_date">
            <div className="col-md-6 col-lg-4 datecat_list">
              {/* {dateList} */}
            </div>
            <div className="col-md-1 col-lg-4"></div>
            <div className="col-md-5 col-lg-4">
              {width < 767 ? <h2 className="share_title">Share</h2> : null}
              <div className="social_main_wrapper">
                <ul className="social_icons">
                  {width > 767 ? (
                    <li className="social_icon_li">
                      <h2 className="share_title">Share</h2>
                    </li>
                  ) : null}
                  <li className="social_icon_li">
                    <FacebookShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={facebook}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </FacebookShareButton>
                  </li>
                  <li className="social_icon_li">
                    <WhatsappShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={whatsapp}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </WhatsappShareButton>
                  </li>
                  <li className="social_icon_li">
                    <TwitterShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={twitterblog}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </TwitterShareButton>
                  </li>
                  <li
                    className="social_icon_li social_icon_li_copy"
                    onClick={copyUrl}
                  >
                    <span className="social_icon">
                      <img
                        src={copy}
                        alt="Sanathan copy url logo"
                        loading="lazy"
                      />
                    </span>
                    <span className="social_name">
                      {!copied ? "" : "Copied!"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="horizontal_line"></div>
          <h1 className="blog_inner_name">{blogDescArr[0].heading}</h1>
          <div>{descList}</div>
        </div>
      </section>
    </>
  );
};

export default BlogInner10;
