import { useEffect } from "react";
import "./ESG.css";

import Dash from "../../components/Dash/Dash";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

import {
  SliderImg1,
  SliderImg2,
  SliderImg3,
  SliderImg4,
  FirstImg,
  SecondImg,
  ThirdImg,
  FourthImg,
  FifthImg,
  CSR1,
  CSR2,
} from "../../images";

//swiper imports
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const ESG = () => {
  const { width } = useWindowDimensions();
  useEffect(() => {
    window.scrollTo(0, 0);
    var esg_second_main_section_slider_wrapper = new Swiper(
      ".swiper-container.esg_second_main_section_slider_wrapper",
      {
        // slidesPerView: 4,
        // spaceBetween: 36,
        allowTouchMove: false,
        breakpoints: {
          1023: {
            slidesPerView: 4,
            spaceBetween: 36,
          },
          768: {
            slidesPerView: 3,
            // autoplay: {
            //     delay: 2000
            // },
            // loop: true,
            // loopedSlides: 4
          },
          375: {
            slidesPerView: 1,
            // autoplay: {
            //     delay: 2000
            // },
            // loop: true,
            // loopedSlides: 4
          },
        },
        navigation: {
          nextEl: ".navigations .right_arrow",
          prevEl: ".navigations .left_arrow",
        },
      }
    );
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/esg"
        metaTitle="Environmental Social Governance | Sanathan Textiles"
        metaDesc="Learn about Sanathan Textiles' ESG commitment. Our setup emphasizes environmental sustainability, social responsibility & ethical governance in the textiles industry. "
      />
      <section className="esg esg_desktop">
        <div className="wrapper" data-aos="fade-in">
          <div class="breadcrumb_styles">
            <Link to="/" className="breadcrumb_link">
              Home
            </Link>{" "}
            {">"} ESG
          </div>
          <div className="left_section">
            <h1 className="hollow_title ">Corporate Social Responsibility</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            {/* <h3>Environmental / Social / Governance</h3> */}
            <p>
              We have constituted a corporate and social responsibility
              committee and have adopted and implemented a CSR Policy pursuant
              to which we carry out CSR activities. In terms of our CSR policy
              our CSR expenditure may be towards, amongst others, eradicating
              hunger, poverty and malnutrition, promoting health care, promoting
              education, promoting gender equality, empowering women, ensuring
              environmental sustainability, ecological balance etc.
            </p>
          </div>
          <figure className="bg_img">
            <img src={FirstImg} alt="sanathan img" />
          </figure>
        </div>
      </section>
      <section className="esg esg_mobile">
        <div className="wrapper">
          <div class="breadcrumb_styles">
            <Link to="/" className="breadcrumb_link">
              Home
            </Link>{" "}
            {">"} ESG
          </div>
        </div>
      </section>
      <div className="esg_mobile_wrapper">
        <div className="wrapper">
          <div className="left_section">
            <div className="hollow_title mb_hollow_title">
              Corporate Social Responsibility
            </div>
            <div className="dash_wrapper mb_dash_wrapper">
              <Dash />
            </div>
            {/* <h3 className="enviro_h3">Environmental / Social / Governance</h3> */}
            <figure className="bg_img">
              <img src={FirstImg} alt="sanathan img" />
            </figure>
          </div>
          <p>
            We have constituted a corporate and social responsibility committee
            and have adopted and implemented a CSR Policy pursuant to which we
            carry out CSR activities. In terms of our CSR policy our CSR
            expenditure may be towards, amongst others, eradicating hunger,
            poverty and malnutrition, promoting health care, promoting
            education, promoting gender equality, empowering women, ensuring
            environmental sustainability, ecological balance etc.
          </p>
        </div>
      </div>
      <section className="esg_main_section">
        <div className="wrapper" data-aos="fade-up">
          <h3 class="main_heading">ENERGY USED FROM RENEWABLE SOURCES</h3>
          <div className="esg_first_main_section">
            <figure className="left_section">
              {width > 767 ? <img src={SecondImg} alt="sanathan img" /> : null}
            </figure>
            <div className="right_section">
              {/* <h3 className="esg_main_section_h3_desktop">
                17% Energy from Renewable Sources
              </h3>
              <h3 className="esg_main_section_h3_mobile">
                17% Energy from Renewable Sources
              </h3> */}
              {width < 767 ? (
                <figure className="left_section">
                  <img src={SecondImg} alt="sanathan img" />
                </figure>
              ) : null}
              <p className="esg_main_section_p_desktop">
                We are an environmentally conscious Company, and we encourage
                the use of renewable resources. Sanathan Textiles have installed
                rooftop solar projects at our Silvassa facility since 2019.
              </p>
              <p className="esg_main_section_p_mobile">
                We are an environmentally conscious Company, and we encourage
                the use of renewable resources. Sanathan Textiles have installed
                rooftop solar projects at our Silvassa facility since 2019.
              </p>
              {/* <figure>
                <img src={ThirdImg} alt="sanathan img" />
              </figure> */}
            </div>
          </div>
          {/* <div className="esg_second_main_section">
                        <div className="esg_second_main_section_heading_and_desc">
                            <h3>Building a better planet <br /> for everyone</h3>
                            <p className="esg_second_main_section_p_desktop">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod <br /> tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At <br /> vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, <br />no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit</p>
                            <p className="esg_second_main_section_p_mobile">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit</p>
                        </div>
                        <div className="esg_second_main_section_slider_wrapper swiper-container">
                            <ul className="swiper-wrapper">
                                <li className="swiper-slide">
                                    <figure>
                                        <img src={SliderImg1} alt="sanathan img"/>
                                    </figure>
                                    <figcaption>Environmental Responsibility</figcaption>
                                </li>
                                <li className="swiper-slide">
                                    <figure>
                                        <img src={SliderImg2} alt="sanathan img"/>
                                    </figure>
                                    <figcaption>Ethical Responsibility</figcaption>
                                </li>
                                <li className="swiper-slide">
                                    <figure>
                                        <img src={SliderImg3} alt="sanathan img"/>
                                    </figure>
                                    <figcaption>Economic Responsibility</figcaption>
                                </li>
                                <li className="swiper-slide">
                                    <figure>
                                        <img src={SliderImg4} alt="sanathan img"/>
                                    </figure>
                                    <figcaption>Philanthropic Responsibility</figcaption>
                                </li>
                            </ul>
                            <div className="navigations">
                                <button className="left_arrow">Previous</button>
                                <button className="right_arrow">Next</button>
                            </div>
                        </div>
                        <div className="csr_section">
                            <div className="left_section">
                                <div className="hollow_title">CSR</div>
                                <h3>Corporate Social Responsibility</h3>
                                <p className="csr_section_desc_desktop">Corporate social responsibility initiatives can, for example, be a powerful <br /> marketing tool, helping a company position itself favorably in the eyes of <br /> consumers, investors, and regulators. CSR initiatives can also improve employee <br /> engagement and satisfaction—key measures that drive retention. Such initiatives <br /> can even attract potential employees who carry strong personal convictions that <br /> match those of the organization. <br /><br />Finally, corporate social responsibility initiatives, by their nature, force business <br /> leaders to examine practices related to how they hire and manage employees, <br /> source products or components, and deliver value to customers. <br /><br />This reflection can often lead to innovative and groundbreaking solutions that <br /> help a company act in a more socially responsible way and increase profits. <br /> Reconceptualizing the manufacturing process so that a company consumes less <br /> energy and produces less waste, for example, allows it to become more <br /> environmentally friendly while reducing its energy and materials costs—value  <br /> that can be reclaimed and shared with both suppliers and customers.</p>
                                <p className="csr_section_desc_mobile">Corporate social responsibility initiatives can, for example, be a powerful marketing tool, helping a company position itself favorably in the eyes of consumers, investors, and regulators. CSR initiatives can also improve employee engagement and satisfaction—key measures that drive retention. Such initiatives can even attract potential employees who carry strong personal convictions that match those of the organization. Finally, corporate social responsibility initiatives, by their nature, force business leaders to examine practices related to how they hire and manage employees, source products or components, and deliver value to customers. This reflection can often lead to innovative and groundbreaking solutions that help a company act in a more socially responsible way and increase profits. Reconceptualizing the manufacturing process so that a company consumes less  energy and produces less waste, for example, allows it to become more environmentally friendly while reducing its energy and materials costs—value  that can be reclaimed and shared with both suppliers and customers.</p>
                            </div>
                            <div className="right_section">
                                <figure className="bg_img">
                                    <img src={CSR1} alt="sanathan img"/>
                                </figure>
                                <figure className="fg_img">
                                    <img src={CSR2} alt="sanathan img"/>
                                </figure>
                            </div>
                        </div>
                    </div> */}
          <div className="esg_third_main_section">
            {width < 767 ? (
              <>
                <h3 className="mobile_heading">BornDyed™ coloured yarns</h3>
              </>
            ) : null}
            <div className="left_section_hollow_box left_section_hollow_box_third">
              <iframe
                src="https://www.youtube.com/embed/OnA4PUhsPfA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="right_section right_section_hollow_box_third">
              {width > 767 ? (
                <>
                  <h3>BornDyed™ coloured yarns</h3>
                </>
              ) : null}

              <p className="esg_third_main_section_p_desktop">
                Our foray into BornDyed™️{" "}
                <Link to="/products/born-dyed" className="new_link">
                  coloured yarns
                </Link>
                , which uses dope dyed technology to produce colour polyester
                yarn, is an solution to producing coloured yarns using dope dyed
                technology. Shortening the supply chain helps in reduced
                production costs and ensures a reduction in the ecological
                footprint of the final products. The products under this
                category are made by using dope dyed technology wherein the
                colour pigment is added to the yarn during the polymerisation
                stage. The fabric manufactured from this yarn is mainly utilized
                in the making of outer-wear, shirts, trousers, suiting,
                rainwear, ties, school belts, bags, upholstery etc.
              </p>
              <p className="esg_third_main_section_p_mobile">
                Our foray into BornDyed™️{" "}
                <Link to="/products/born-dyed" className="new_link">
                  coloured yarns
                </Link>
                , which uses dope dyed technology to produce colour polyester
                yarn, is an solution to producing coloured yarns using dope dyed
                technology. Shortening the supply chain helps in reduced
                production costs and ensures a reduction in the ecological
                footprint of the final products. The products under this
                category are made by using dope dyed technology wherein the
                colour pigment is added to the yarn during the polymerisation
                stage. The fabric manufactured from this yarn is mainly utilized
                in the making of outer-wear, shirts, trousers, suiting,
                rainwear, ties, school belts, bags, upholstery etc.
              </p>
            </div>
          </div>
          <div className="esg_fourth_main_section esg_fourth_main_section_desktop">
            <div className="left_section">
              <h3>Sanathan Reviro</h3>
              <p className="esg_fourth_main_section_p_desktop esg_fourth_desk">
                We manufacture products from recycled materials which are sold
                under the brand ‘Sanathan Reviro’...
              </p>
              <p className="esg_fourth_main_section_p_mobile">
                We manufacture products from recycled materials which are sold
                under the brand ‘Sanathan Reviro’...
              </p>
            </div>
            <div className="right_section_hollow_box">
              <iframe
                src="https://www.youtube.com/embed/-c-GVI-foRs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="esg_fourth_main_section esg_fourth_main_section_mobile">
            <h3>Sanathan Reviro</h3>
            <div className="right_section_hollow_box right_section_hollow_box_sanathan_reviro">
              <iframe
                src="https://www.youtube.com/embed/-c-GVI-foRs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="left_section">
              <p className="esg_fourth_main_section_p_desktop">
                We manufacture products from recycled materials which are sold
                under the brand ‘Sanathan Reviro’...
              </p>
              <p className="esg_fourth_main_section_p_mobile">
                We manufacture products from recycled materials which are sold
                under the brand ‘Sanathan Reviro’...
              </p>
            </div>
          </div>
          <div className="esg_fifth_main_section">
            {width < 767 ? (
              <>
                <div className="hollow_title">CSR</div>
                {/* <h3>Corporate Social Responsibility</h3> */}
                {/* <h4 className="mobile_heading">Child Development Program</h4> */}
              </>
            ) : null}
            <div className="left_section_hollow_box">
              <iframe
                // width="815"
                // height="671"
                src="https://www.youtube.com/embed/ZSsNEXs86Pc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="right_section">
              {width > 767 ? (
                <>
                  <div className="hollow_title">CSR</div>
                  {/* <h3>Corporate Social Responsibility</h3>
                  <h4>Child Development Program</h4> */}
                </>
              ) : null}
              <p className="esg_fifth_main_section_p_desktop">
                We have constituted a corporate and social responsibility
                committee and have adopted and implemented a CSR Policy pursuant
                to which we carry out CSR activities. In terms of our CSR policy
                our CSR expenditure may be towards, amongst others, eradicating
                hunger, poverty and malnutrition, promoting health care,
                promoting education, promoting gender equality, empowering
                women, ensuring environmental sustainability, ecological balance
                etc.
              </p>
              <p className="esg_fifth_main_section_p_mobile">
                We have constituted a corporate and social responsibility
                committee and have adopted and implemented a CSR Policy pursuant
                to which we carry out CSR activities. In terms of our CSR policy
                our CSR expenditure may be towards, amongst others, eradicating
                hunger, poverty and malnutrition, promoting health care,
                promoting education, promoting gender equality, empowering
                women, ensuring environmental sustainability, ecological balance
                etc.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="new_renuable_energy new_renuable_energy_desktop">
        <div className="left_section">
          <h3>
            Sanathan Textiles in Association <br /> with Dattani Foundation.
          </h3>
          <p className="new_renuable_energy_p_desktop">
            On behalf of all our colleagues, clients and business associates, we{" "}
            <br /> have extended our support in our small way towards the <br />{" "}
            following causes: <br />
            <br />
          </p>
          <ul className="points_wrapper">
            <li>
              <p>
                Donation to Administrators COVID relief fund of Dadra and Nagar
                Haveli and Daman and Diu
              </p>
            </li>
            <li>
              <p>
                Supported the Federation of Industries Association, Silvassa to
                fight the Covid situation in DNH
              </p>
            </li>
            <li>
              <p>
                Donated & Assisted in the Installation of ventilators at Shri
                Vinoba Bhave Civil Hospital, Silvassa
              </p>
            </li>
            <li>
              <p>
                Supported Quarancharity in its mission to help both people and
                animals suffering during the Covid-19 lockdown
              </p>
            </li>
            <li>
              <p>Collaborated with Akshay Patra Foundation for food donation</p>
            </li>
            <li>
              <p>
                Participated with Healthy Aging India for their meal
                distribution
              </p>
            </li>
          </ul>
        </div>
        <div className="right_section">
          <img src={FifthImg} alt="sanathan img" />
        </div>
      </section>
      <section className="new_renuable_energy new_renuable_energy_mobile">
        <div className="right_section">
          <img src={FifthImg} alt="sanathan img" />
        </div>
        <div className="left_section">
          <h3>Sanathan Textiles in Association with Dattani Foundation.</h3>
          <p className="new_renuable_energy_p_mobile">
            On behalf of all our colleagues, clients and business associates, we
            have extended our support in our small way towards the following
            causes: <br />
            <br />
          </p>
          <ul className="points_wrapper">
            <li>
              <p>
                Donation to Administrators COVID relief fund of Dadra and Nagar
                Haveli and Daman and Diu
              </p>
            </li>
            <li>
              <p>
                Supported the Federation of Industries Association, Silvassa to
                fight the Covid situation in DNH
              </p>
            </li>
            <li>
              <p>
                Donated & Assisted in the Installation of ventilators at Shri
                Vinoba Bhave Civil Hospital, Silvassa
              </p>
            </li>
            <li>
              <p>
                Supported Quarancharity in its mission to help both people and
                animals suffering during the Covid-19 lockdown
              </p>
            </li>
            <li>
              <p>Collaborated with Akshay Patra Foundation for food donation</p>
            </li>
            <li>
              <p>
                Participated with Healthy Aging India for their meal
                distribution
              </p>
            </li>
          </ul>
        </div>
      </section>
      {/* <section className="renuable_energy renuable_energy_desktop">
                <div className="wrapper" data-aos="fade-up">
                    <div className="hollow_title">Renuable <br /> Energy</div>
                    <div className="content">
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor <br /> invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam <br /> et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est <br /> Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor <br /> invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam <br /> et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est <br /> Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                    </div>
                </div>
                <figure>
                    <img src={FourthImg} alt="sanathan img"/>
                </figure>
            </section>
            <section className="renuable_energy renuable_energy_mobile">
                <div className="wrapper" data-aos="fade-up">
                    <div className="hollow_title">Renuable <br /> Energy</div>
                    <figure>
                        <img src={FourthImg} alt="sanathan img"/>
                    </figure>
                    <div className="content content_desktop">
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor <br /> invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam <br /> et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est <br /> Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor <br /> invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam <br /> et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est <br /> Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                    </div>
                    <div className="content content_mobile">
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                    </div>
                </div>
            </section> */}
    </>
  );
};

export default ESG;
