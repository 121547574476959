import { useEffect } from "react";
import "./AboutUsTheCompany.css";

import Dash from "../../components/Dash/Dash";

import {
  BannerImage1,
  BannerImage2,
  BannerImage1_mobile,
  BrandImage1,
  BrandImage2,
  BrandImage3,
  BrandImage4,
  BrandImage5,
  BrandImage6,
  BrandImage7,
  BrandImage8,
  BrandImage9,
  BrandImage10,
  BrandImage11,
  BrandImage12,
  BrandImage13,
  BrandImage14,
  BrandImage15,
  BrandImage16,
  BrandImage17,
  BrandImage18,
  BrandImage19,
  BrandImage20,
  BrandImage21,
  BrandImage22,
  BrandImage23,
  BrandImage24,
  BrandImage25,
  BrandImage26,
  BrandImage27,
  BrandImage28,
  BrandImage29,
  BrandImage30,
  LogoImg1,
  LogoImg2,
  LogoImg3,
  LogoImg4,
  LogoImg5,
} from "../../images";

//swiper imports
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import { Link } from "react-router-dom";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const AboutUsTheCompany = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    // var subsidiaries_slider = new Swiper(
    //     ".swiper-container.subsidiaries_slider",
    //     {
    //         slidesPerView: 4.7,
    //         spaceBetween: 80,
    //         navigation: {
    //             nextEl: ".navigations .right_arrow",
    //             prevEl: ".navigations .left_arrow",
    //         },
    //         breakpoints: {
    //             768: {
    //                 slidesPerView: 3,
    //                 spaceBetween: 80,
    //             },
    //             375: {
    //                 slidesPerView: 1.5,
    //                 spaceBetween: 40,
    //             }
    //         }
    //     }
    // );

    var clients_slider_wrapper = new Swiper(
      ".swiper-container.clients_slider_wrapper",
      {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        freeMode: true,
        grabCursor: false,
        allowTouchMove: false,
        slidesPerView: 7,
        autoplay: {
          delay: 1,
          disableOnInteraction: true,
        },
        speed: 2500,
        navigation: {
          nextEl: ".clients_slider_wrapper .right_arrow",
          prevEl: ".clients_slider_wrapper .left_arrow",
        },
        spaceBetween: 40,
        breakpoints: {
          300: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
          },
          1023: {
            slidesPerView: 5.5,
          },
        },
      }
    );
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/about-us/the-company"
        metaTitle="About Us - Our Company |Sanathan Textiles"
        metaDesc="Learn about Sanathan Textiles, a distinguished yarn manufacturing company in India. With a rich legacy and innovative approach, we are key players in the textiles industry. Explore now!"
      />

      <section className="aboutus_the_company">
        <div className="wrapper">
          <div class="breadcrumb_styles">About us {">"} Our Company</div>
          <div className="left_section">
            <h1 className="hollow_title">Our Company</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            {/* <h3>Knitting the textile <br /> industry for generations</h3> */}
            <p>
              We are Sanathan Textiles, a{" "}
              <Link to="/" className="new_link">
                polyester yarn manufacturer
              </Link>{" "}
              and a{" "}
              <Link to="/products/cotton-yarn" className="new_link">
                global supplier of Cotton Yarns
              </Link>
              ,{" "}
              <Link to="/products/polyester-yarn" className="new_link">
                Polyester Yarns
              </Link>{" "}
              and{" "}
              <Link
                to="/products/yarn-for-technical-textile"
                className="new_link"
              >
                Yarns for Technical Textiles
              </Link>
              . Having 3 yarn business verticals gives us the unique opportunity
              to offer a wide variety of yarns that are used by various sectors
              and industries, across applications and end-uses.
              <br />
              <br />
              From April 1, 2020, to December 31, 2023, our Company has catered
              to 2,588 customers in the form of products such as apparel, home
              furnishings, luggage, ropes, geogrid fabrics, seatbelts,
              upholstery, sportswear, innerwear, umbrellas, personal protective
              equipment, pipes, all made from our yarns!
              <br />
              <br />
              We have an inhouse testing facility with equipment, such as wrap
              reel machine and weigh balance for denier testing (i.e. to
              determine the fibre thickness of individual threads or filaments),
              yarn strength tester to determine tensile properties (i.e., to
              check elongation and tenacity of yarns) and color viewing booth
              for visual colour evaluation. We have harnessed the benefits of
              technology and digitization to bring about cohesion in planning,
              production and processing of our products. We intend to continue
              to upgrade our equipment which we expect will improve our
              operating efficiencies and help in reducing the cost of
              production.
              <br />
              <br />
              We have an in-house Product Innovation and Development team that
              is continually focusses on developing value added products and
              using our existing machines and infrastructure to prepare
              customized made to order products. Value added products and
              customisation of products is an integral element to ensuring
              longevity of customer relationships and repeat business. We offer
              a varied range of value added products which constitute a
              significant proportion of our revenues have higher margins since
              these are tailor-made to customer requirements and distinctive
              properties and characteristics.
              <br />
              <br />
              We attribute our growth to the experience of our Promoters and
              senior management team. Our Promoters have cumulative experience
              of approximately 160 years in the textiles industry with more than
              48 years in the yarn business. Our Promoter and Chairman &
              Managing Director, Paresh Vrajlal Dattani has over 44 years of
              experience in textiles industry and has been responsible in
              augmenting relationships with various stakeholders which has
              helped our Company expand by increasing its product portfolio on a
              continuous basis. Our Joint Managing Director and Promoter, Ajay
              Vallabhdas Dattani, our Promoters, Anilkumar Vrajdas Dattani and
              DineshVrajdas Dattani, all have considerable experience in the
              textile industry, which has contributed to the growth trajectory
              of our Company
            </p>
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img
                src={BannerImage1}
                className="bg_img_desktop"
                alt="sanathan img"
              />
              <img
                src={BannerImage1_mobile}
                className="bg_img_mobile"
                alt="sanathan img"
              />
            </figure>
            <figure className="fg_img">
              <img
                className="image_animate"
                src={BannerImage2}
                alt="sanathan img"
              />
            </figure>
          </div>
          {/* <div className="additional_content">
                        <h2>The best premium raw materials</h2>
                        <p>worked in detail with love</p>
                        <span className="dotted_circle"></span>
                    </div> */}
        </div>
      </section>
      <section className="clients" style={{ marginTop: "0" }}>
        <div className="clients_slider_wrapper swiper-container">
          <div className="clients_slider_wrapper_heading_section">
            <div className="left_section">
              <div className="hollow_title">Our Customers</div>
              <h3>we supply to 1150+ customers worldwide</h3>
            </div>
            {/* <div className="navigations">
              <button className="left_arrow">Previous</button>
              <button className="right_arrow">Next</button>
            </div> */}
          </div>
          <ul className="swiper-wrapper">
            <li className="swiper-slide">
              <img src={BrandImage1} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage2} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage3} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage4} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage5} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage6} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage7} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage8} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage9} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage10} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage11} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage12} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage13} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage14} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage15} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage16} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage17} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage18} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage19} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage20} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage21} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage22} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage23} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage24} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage25} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage26} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage27} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage28} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage29} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={BrandImage30} alt="sanathan img" />
            </li>
          </ul>
          {/* <div class="swiper-pagination"></div> */}
        </div>
      </section>
      {/* <section className="aboutus_subsidiaries">
                <div className="wrapper">
                    <div className="subsidiaries_heading">
                        <div className="hollow_title">Subsidiaries</div>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren</p>
                    </div>
                </div>
                <div className="subsidiaries_slider swiper-container">
                    <ul className="swiper-wrapper p-0">
                        <li className="swiper-slide">
                            <figure>
                                <img src={BrandImage1} alt="sanathan img"/>
                            </figure>
                        </li>
                        <li className="swiper-slide">
                            <figure>
                                <img src={BrandImage2} alt="sanathan img"/>
                            </figure>
                        </li>
                        <li className="swiper-slide">
                            <figure>
                                <img src={BrandImage3} alt="sanathan img"/>
                            </figure>
                        </li>
                        <li className="swiper-slide">
                            <figure>
                                <img src={BrandImage4} alt="sanathan img"/>
                            </figure>
                        </li>
                        <li className="swiper-slide">
                            <figure>
                                <img src={BrandImage1} alt="sanathan img"/>
                            </figure>
                        </li>
                        <li className="swiper-slide">
                            <figure>
                                <img src={BrandImage2} alt="sanathan img"/>
                            </figure>
                        </li>
                    </ul>
                </div>
                <div className="navigations">
                    <button className="left_arrow">Previous</button>
                    <button className="right_arrow">Next</button>
                </div>
            </section> */}
    </>
  );
};

export default AboutUsTheCompany;
