import { useEffect, useState } from "react";
import "./ProductsInner.scss";
import "./style.scss";

import Dash from "../../components/Dash/Dash";

import ProductsPopup from "../../components/ProductsPopup/ProductsPopup";

import {
  // coloredArrow,
  cottonyarn,
  cottonyarn1,
  cottonyarn2,
  cottonyarn3,
  cottonyarn4,
  cottonyarn5,
  cottonyarn6,
  // youtube_cotton,
  // playicon,
} from "../../images";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import InfoModal from "../../components/VideoModal/VideoModal";
import { Link } from "react-router-dom";

const CottonYarn = () => {
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [shouldModalOpen, setShouldModalOpen] = useState(false);
  const [ytVideoLink, setYtVideoLink] = useState("");

  const handleProjectVideoClick = (ytLink) => {
    setYtVideoLink(ytLink);
    setShouldModalOpen(true);
  };

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/products/cotton-yarn"
        metaTitle="Pure Cotton Yarn - Cotton Yarn Manufacturer | Sanathan Textiles"
        metaDesc='Find high-quality pure cotton yarn "PURO" for apparels,bedsheets, shirts etc. from top cotton yarn manufacturers in India. Explore our range of cotton yarn fabrics designed for excellence.'
      />

      {isModalOpen && <ProductsPopup onRequestClose={toggleModal} />}
      <section className="products_inner" data-aos="fade-up">
        <div className="wrapper">
          <div class="breadcrumb_styles">Products {">"} Cotton Yarns</div>
          <div className="left_section">
            <p className="hollow_title">Products</p>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <h1 className="yarns">Cotton yarns</h1>
            <h4 className="capacity">
              Our facility at Silvassa had a total installed capacity of 14,000
              MTPA
            </h4>
            <p>
              Our range of Pure Cotton Yarns marketed under our brand ‘Puro
              Cotton Yarns’ is used to make fabrics for apparel, suiting,
              shirting, bedsheets, home textile and other end uses.
              <br />
              <br />
              This product range is keenly focused on making fine count compact
              yarns.
              <br />
              <br />
              By sourcing raw pieces of cotton in India and around the world, we
              spin our cotton yarns to perfection at our &nbsp;
              <Link to="/our-setup/tech-in-textile" className="new_link">
                textile facility in Silvassa, India
              </Link>.
            </p>
            {/* <p className="products_inner_p_mobile">
              We offer a extensive range of Yarn products across many yarn
              categories in the <br /> polyester portfolio. Our integrated well
              designed continuous polymerization setup <br /> with 3 lustres
              give us the flexibility to meet the customers every Yarn product{" "}
              <br /> need.
            </p> */}
            {/* <img src={coloredArrow} className="arrow" alt="sanathan img"/> */}
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={cottonyarn} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="products_inner_watch_now">
        <div className="wrapper">
          <div className="hollow_title">Watch Now</div>
          {/* <div className="video_section">
            <a>
              <img src={youtube_cotton} className="video_image" alt="sanathan img"/>
              <img
                src={playicon}alt="sanathan img"
                className="playicon"
                onClick={() =>
                  handleProjectVideoClick(
                    "https://www.youtube.com/embed/a6y1xzN1WD8"
                  )
                }
              />
            </a>
          </div> */}
          <figure className="for_desk">
            <iframe
              width="100%"
              height="680"
              src="https://www.youtube.com/embed/31msP4ZPtmQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </figure>
          <figure className="for_mob">
            <iframe
              width="100%"
              height="220"
              src="https://www.youtube.com/embed/31msP4ZPtmQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </figure>
        </div>
      </section>

      <section className="products_inner_list" data-aos="fade-up">
        <div className="wrapper">
          <div className="products_inner_list_heading_wrapper">
            <div className="hollow_title">Manufacturing unit</div>
            {/* <h3>
              Click on any product to view <br /> detailed information
            </h3> */}
          </div>
          <div className="products_inner_listing">
            <div className="product_box">
              <figure>
                <img src={cottonyarn1} alt="sanathan img" />
              </figure>
              {/* <button onClick={toggleModal}>Partially Oriented Yarn</button> */}
            </div>
            <div className="product_box">
              <figure>
                <img src={cottonyarn2} alt="sanathan img" />
              </figure>
              {/* <button onClick={toggleModal}>Fully Drawn Yarn</button> */}
            </div>
            <div className="product_box">
              <figure>
                <img src={cottonyarn3} alt="sanathan img" />
              </figure>
              {/* <button onClick={toggleModal}> Draw Textured/Texturized Yarn </button> */}
            </div>
            <div className="product_box">
              <figure>
                <img src={cottonyarn4} alt="sanathan img" />
              </figure>
              {/* <button onClick={toggleModal}>Air Textured Yarn</button> */}
            </div>
            <div className="product_box">
              <figure>
                <img src={cottonyarn5} alt="sanathan img" />
              </figure>
              {/* <button onClick={toggleModal}>Twisted Yarns</button> */}
            </div>
            <div className="product_box">
              <figure>
                <img src={cottonyarn6} alt="sanathan img" />
              </figure>
              {/* <button onClick={toggleModal}>Twisted Yarns</button> */}
            </div>
          </div>
        </div>
      </section>
      <InfoModal
        show={shouldModalOpen}
        onHide={() => setShouldModalOpen(false)}
        ytvideolink={ytVideoLink}
      />
    </>
  );
};

export default CottonYarn;
