import { useEffect, useState } from "react";
import "./AboutUsManagement.css";

import Dash from "../../components/Dash/Dash";
import Profiles from "../../components/Profiles/Profiles";

import {
  JoinUs,
  JoinUsMobile,
  BOD1,
  BOD2,
  BOD3,
  BOD4,
  BOD5,
  BOD6,
  BOD7,
  BOD8,
  M1,
  M2,
  M3,
  M4,
  M6,
  M7,
  M8,
  M9,
  M10,
  M11,
  M12,
  M13,
  M14,
  BannerRight,
  ManagementTeam,
  whiteArrow,
  deepakPrasad,
  gulvinder,
  kaushikMody,
  satishChitre,
  senthilvel,
  venkobaraoSrinivas,
  BOD9,
  M15,
} from "../../images";

//swiper imports
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";
import Careers from "../../components/Careers/Careers";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const AboutUsManagement = () => {
  const [isModalCareersOpen, setCareersModalIsOpen] = useState(false);
  const toggleCareersModal = () => {
    setCareersModalIsOpen(!isModalCareersOpen);
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    var board_of_directors_container_mobile = new Swiper(
      ".swiper-container.board_of_directors_container_mobile",
      {
        simulateTouch: false,
        loop: true,
        autoplay: true,
        // loop: true,
        // loopedSlides: 4,
        // navigation: {
        //     nextEl: ".navigations .right_arrow",
        //     prevEl: ".navigations .left_arrow",
        // },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 36,
          },
          375: {
            slidesPerView: 1.2,
            spaceBetween: 36,
          },
        },
      }
    );

    var management_team_thumb_container = new Swiper(
      ".swiper-container.management_team_thumb_container",
      {
        slidesPerView: 4,
        spaceBetween: 20,
        simulateTouch: false,
        // loop: true,
        // autoplay: true,
        // loop: true,
        // loopedSlides: 4,
        // breakpoints: {
        //     768: {
        //         slidesPerView: 3
        //     },
        //     375: {
        //         slidesPerView: 1.2
        //     }
        // }
      }
    );

    var management_team_container = new Swiper(
      ".swiper-container.management_team_container",
      {
        slidesPerView: 4,
        spaceBetween: 20,
        simulateTouch: false,
        // loop: true,
        // autoplay: true,
        // loopedSlides: 4,
        navigation: {
          nextEl: ".navigations .right_arrow",
          prevEl: ".navigations .left_arrow",
        },
        // breakpoints: {
        //     768: {
        //         slidesPerView: 3
        //     },
        //     375: {
        //         slidesPerView: 0
        //     }
        // }
      }
    );

    var management_team_container_mobile = new Swiper(
      ".swiper-container.management_team_container_mobile",
      {
        simulateTouch: false,
        loop: true,
        autoplay: true,
        // loopedSlides: 4,
        navigation: {
          nextEl: ".navigations .right_arrow",
          prevEl: ".navigations .left_arrow",
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 36,
          },
          375: {
            slidesPerView: 1.2,
            spaceBetween: 36,
          },
        },
      }
    );

    management_team_thumb_container.controller.control =
      management_team_container;
    management_team_container.controller.control =
      management_team_thumb_container;
  }, []);

  const [isModal1Open, setModal1IsOpen] = useState();
  const [isModal2Open, setModal2IsOpen] = useState();
  const [isModal3Open, setModal3IsOpen] = useState();
  const [isModal4Open, setModal4IsOpen] = useState();
  const [isModal5Open, setModal5IsOpen] = useState();
  const [isModal6Open, setModal6IsOpen] = useState();
  const [isModal7Open, setModal7IsOpen] = useState();
  const [isModal8Open, setModal8IsOpen] = useState();
  const [isModal9Open, setModal9IsOpen] = useState();
  const toggleModal1 = () => {
    setModal1IsOpen(!isModal1Open);
  };
  const toggleModal2 = () => {
    setModal2IsOpen(!isModal2Open);
  };
  const toggleModal3 = () => {
    setModal3IsOpen(!isModal3Open);
  };
  const toggleModal4 = () => {
    setModal4IsOpen(!isModal4Open);
  };
  const toggleModal5 = () => {
    setModal5IsOpen(!isModal5Open);
  };
  const toggleModal6 = () => {
    setModal6IsOpen(!isModal6Open);
  };
  const toggleModal7 = () => {
    setModal7IsOpen(!isModal7Open);
  };
  const toggleModal8 = () => {
    setModal8IsOpen(!isModal8Open);
  };
  const toggleModal9 = () => {
    setModal9IsOpen(!isModal9Open);
  };

  const [isModalM1Open, setModalM1IsOpen] = useState();
  const [isModalM2Open, setModalM2IsOpen] = useState();
  const [isModalM3Open, setModalM3IsOpen] = useState();
  const [isModalM4Open, setModalM4IsOpen] = useState();
  const [isModalM5Open, setModalM5IsOpen] = useState();
  const [isModalM6Open, setModalM6IsOpen] = useState();
  const [isModalM7Open, setModalM7IsOpen] = useState();
  const [isModalM8Open, setModalM8IsOpen] = useState();
  const [isModalM9Open, setModalM9IsOpen] = useState();
  const [isModalM10Open, setModalM10IsOpen] = useState();
  const [isModalM11Open, setModalM11IsOpen] = useState();
  const [isModalM12Open, setModalM12IsOpen] = useState();
  const [isModalM13Open, setModalM13IsOpen] = useState();
  const [isModalM14Open, setModalM14IsOpen] = useState();
  const toggleModalM1 = () => {
    setModalM1IsOpen(!isModalM1Open);
  };
  const toggleModalM2 = () => {
    setModalM2IsOpen(!isModalM2Open);
  };
  const toggleModalM3 = () => {
    setModalM3IsOpen(!isModalM3Open);
  };
  const toggleModalM4 = () => {
    setModalM4IsOpen(!isModalM4Open);
  };
  const toggleModalM5 = () => {
    setModalM5IsOpen(!isModalM5Open);
  };
  const toggleModalM6 = () => {
    setModalM6IsOpen(!isModalM6Open);
  };
  const toggleModalM7 = () => {
    setModalM7IsOpen(!isModalM7Open);
  };
  const toggleModalM8 = () => {
    setModalM8IsOpen(!isModalM8Open);
  };
  const toggleModalM9 = () => {
    setModalM9IsOpen(!isModalM9Open);
  };
  const toggleModalM10 = () => {
    setModalM10IsOpen(!isModalM10Open);
  };
  const toggleModalM11 = () => {
    setModalM11IsOpen(!isModalM11Open);
  };
  const toggleModalM12 = () => {
    setModalM12IsOpen(!isModalM12Open);
  };
  const toggleModalM13 = () => {
    setModalM13IsOpen(!isModalM13Open);
  };
  const toggleModalM14 = () => {
    setModalM14IsOpen(!isModalM14Open);
  };

  // const [showModal1, setShowModal1] = useState(false);

  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Meet Our Team - Experts in the Yarn Industry"
        metaDesc="Get to know the experts behind Sanathan Textiles. Our team experience & dedication drive our success as pioneers in the yarn and textiles industry. Explore our team’s expertise!"
      />

      {isModal1Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModal1}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              {/* <h6>Paresh V. Dattani</h6>
              <p>Chairman and Managing Director</p> */}
              <ul className="modal_data">
                <li style={{ listStyle: "none" }}>
                  <p>
                    <strong>Paresh Vrajlal Dattani </strong>, aged 63 is the
                    Chairman and Managing Director of our Company. He has
                    completed his degree in Science from University of Calcutta.
                    He has been on the Board of our Company since October 10,
                    2005. He is responsible for supervision of overall
                    performance of our Company. He contributes in formulating
                    strategies for the growth of our Company and provides
                    guidance and direction to our Key Managerial Personnel and
                    members of Senior Management
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModal2Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModal2}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              {/* <h6>Ajay V. Dattani</h6>
              <p>Joint Managing Director</p> */}
              <ul className="modal_data">
                <li style={{ listStyle: "none" }}>
                  <p>
                    <strong>Ajay Vallabhdas Dattani</strong>, aged 56 is the
                    Joint Managing Director of our Company. He has been on the
                    Board of our Company since October 10, 2005 and is
                    responsible for managing the operations and expansion of our
                    cotton division. He also supervises the various functions in
                    our Company inter alia finance, production and compliance.
                    He has a bachelor’s degree in commerce (honors) from the
                    University of Calcutta. He has over 18 years of experience
                    in the textile industry.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModal3Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModal3}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <ul className="modal_data">
                <li style={{ listStyle: "none" }}>
                  <p>
                    <strong>Dinesh Vrajdas Dattani </strong>, aged 75 is an
                    Executive Director of our Company. He holds a degree of
                    bachelor’s in commerce from University of Calcutta. He has
                    19 years of work experience in the textile industry. He is
                    responsible for providing strategic inputs for our business
                    operations based on his experience in the textile industry.
                    He also holds directorships in Vajubhai Investments Private
                    Limited and Universal Texturisers Private Limited.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModal4Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModal4}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              {/* <h6>Anilkumar Dattani</h6>
              <p>Executive Director</p> */}
              <ul className="modal_data">
                <li style={{ listStyle: "none" }}>
                  <p>
                    <strong>Anilkumar Vrajdas Dattani</strong>, is an Executive
                    Director of our Company. He has been on the Board of our
                    Company since October 10, 2005 and his responsibilities
                    include overseeing various functions of our Company inter
                    alia corporate social responsibility and administration in
                    the Company. He has passed the examination for bachelor’s
                    degree in commerce from University of Bombay.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModal5Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModal5}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              {/* <h6>Debabrata Sarkar</h6>
              <p>Independent Director</p> */}
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Debabrata Sarkar</strong>, is an Independent
                    Director of our Company. He has been on the Board of our
                    Company since November 22, 2021. He is a fellow of ‘The
                    Institute of Chartered Accountants of India’ and holds a
                    master’s degree in commerce from University of Calcutta. He
                    is also certified associate of the Indian Institute of
                    Bankers. He was appointed in the officers’ cadre as a
                    chartered accountant at Bank of Baroda in 1982. He was an
                    executive director of Allahabad Bank in 2009 and was
                    appointed as the chairman and managing director of Union
                    Bank of India with effect from April 2012 and retired from
                    this position on November 30, 2013. Further, he was a
                    nonexecutive chairman and nominee director of Union KBC
                    Asset Management Co. Private Limited. He has vast experience
                    in the Banking and finance sector.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModal6Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModal6}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <h6>Bhumika Batra</h6>
              <p>Independent Director</p>
              <ul className="modal_data">
                <li>
                  <p>
                    She has been on the Board of our Company since November 22,
                    2021.
                  </p>
                </li>
                <li>
                  <p>
                    She holds a bachelor’s degree in commerce and from Devi
                    Ahilya Vishwavidyalaya, Indore and law from Symbiosis
                    International Educational Centre, Pune.
                  </p>
                </li>
                <li>
                  <p>
                    She has over 16 years of experience in corporate laws and
                    matters related therto.
                  </p>
                </li>
                <li>
                  <p>
                    She is associate partner with M/s. Crawford Bayley & Co.,
                    Advocates and Solicitors.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModal7Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModal7}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              {/* <h6>Khurshed Thanawalla</h6>
              <p>Independent Director</p> */}
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Khurshed Thanawalla</strong>, is an Independent
                    Director of our Company. He is appointed on the Board of our
                    Company on November 22, 2021. He was a member of The
                    Institute of Chartered Secretaries & Administrators, London.
                    He is a fellow of the Chartered Management Institute, Corby,
                    United Kingdom. He has over 39 years of experience across
                    the spectrum of the textiles industry. He retired as Country
                    Representative – India for Oerlikon Group in 2016 and was
                    serving as managing director from 1985 till 2016, and as a
                    chairman of Oerlikon Textile India Private Limited till
                    2021. He was also a chairman of Rotaries Screens of Asia
                    (Pte) Ltd., Singapore till 1978.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModal8Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModal8}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Vinay Aggarwal</strong>, is an Independent Director
                    of our Company. He has been on the Board of our Company
                    since November 22, 2021. He holds a bachelor’s degree in
                    commerce from Sydenham College of Commerce and Economics,
                    University of Bombay, and a master’s degree in business
                    administration from the Northeastern University, Boston,
                    Massachusetts, United States of America. He has several
                    years of experience in finance and was previously associated
                    with Tata Consultancy Services and also served as a director
                    of finance for Tata Burroughs Limited, an Indian entity of
                    Burroughs Corporation.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModal9Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModal9}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Rupal Vora</strong>, is an Independent Director of
                    our Company and has been on the Board of our Company since
                    March 31, 2023. She holds a bachelor’s degree in commerce
                    from Sydenham College of Commerce and Economics, University
                    of Bombay, followed by a bachelor’s degree in law from
                    Government Law College, University of Bombay. She's a member
                    of Bar council of Maharashtra and Goa bar association. She
                    is a member of the Instituteof Directors since 2020.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModalM1Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM1}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Deepak Prasad</strong>, is the Chief Safety and
                    Security Officer of our Company. He has been associated with
                    the Company since September 23, 2021. He holds a bachelor’s
                    degree in science (honors) from Ranchi University and
                    master’s degree in business administration (human resource
                    and marketing) from Veer Bahadur Singh Purvanchal
                    University. He is responsible for ensuring safety and
                    security of employees and conducting necessary safety
                    processes for our manufacturing units. He is an
                    ex-commission officer at commander rank in Indian Navy
                    (Armed Forces) having over 10 years of experience. He has
                    experience in handling security, safety and specialisation
                    in firefighting operation including
                    Nuclear/Biological/Chemical. He has also completed a course
                    on Damage Control Fire Fighting (NBCD) from NBCD School, INS
                    Shivaji, Lonavala, Maharashtra. He also has specialisation
                    in CSP (Certified Security Professional) which is an
                    internationally accredited certification in Security &
                    Safety domain. Prior to joining our Company, he was
                    associated with Reliance Jio Infocom Limited and Tata Steel
                    BSL Limited.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModalM2Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM2}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Beena Paresh Dattani </strong>, is the Director –
                    Social Welfare of our Company. She has been appointed at
                    this designation on January, 2024. She has passed the
                    examination for bachelor’s degree in commerce from
                    University of Bombay and attended University of Bombay for
                    legal studies. She holds a diploma in nutrition and diet
                    planning from Tulip International and is registered as an
                    acupuncture practitioner with Indian Academy of Acupuncture
                    Science, Aurangabad, India. Her responsibility includes
                    Social Engagement partnership, employees benefits and
                    engagement. She is also appointed as the Chairperson of the
                    POSH Committee of our Company. Since 2013, she has been
                    associated with our Company for handling administrative work
                    at the head office of our Company.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModalM3Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM3}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Jude Patrick Dsouza</strong>, is the Company
                    Secretary and Compliance Officer of our Company. He has been
                    associated with our Company since November 2, 2021. He is an
                    associate member of ‘The Institute of Company Secretaries of
                    India’. He holds a bachelor’s degree in commerce and a
                    bachelor’s degree in law from University of Mumbai. Prior to
                    joining our Company, he worked in the corporate secretarial
                    team of Tata Communications Limited. He has also handled the
                    secretarial and legal work for Radhakrishna Foodland Group.
                    He has over 8 years of experience in corporate secretarial
                    and legal work which also includes CSR and Sustainability
                    Development Projects. He plays a pivotal role in managing
                    secretarial, legal and compliance functions of our Company.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModalM4Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM4}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              {/* <h5>Mikesh Dattani</h5>
              <p>Director – Production Planning</p> */}
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Mikesh Dattani</strong>, is the Director –
                    Production, Planning and Marketing of our Company. He has
                    been associated with our Company since January 1, 2011. He
                    has passed the examination for bachelor’s degree in science
                    (Polymers and Fibres Chemistry) from Clemson University. He
                    oversees the entire production planning and analysis at
                    Silvassa unit. He has over 13 years of experience in
                    textiles industry.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModalM5Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM5}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Sanjay Anirudh Shah </strong>, is the Chief
                    Financial Officer of our Company. He has been associated
                    with our Company since March 16, 2024. He is an associate
                    member of The Institute of Chartered Accountants of India.
                    He has passed the examination for bachelor’s degree in
                    commerce and master’s degree in commerce from the University
                    of Mumbai. Prior to joining our Company, he was associated
                    with Yashraj Biotechnology Limited, The Bombay Dyeing and
                    Manufacturing Company Limited, Shrenuj and Company Limited,
                    ICICI Prudential Life Insurance Company Limited, Hutchison
                    Essar Limited (now Vodafone Idea Limited), L&T Finance
                    Limited and Kanyakumari Builders Private Limited. He has
                    several of experience in the field of finance. He oversees
                    the financial management and formulates financial strategies
                    of our Company.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModalM6Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM6}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              {/* <h5>Palanisamy Mandira Moorthy</h5>
              <p>President – Cotton Division</p> */}
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Mandiramoorthy Palanisamy </strong>, is the
                    President - Cotton Division of our Company. He has been
                    associated with our Company since December 6, 2006. He holds
                    a diploma in textile technology from State Board of
                    Technical Education and Training, Tamil Nadu. He was
                    previously associated with SCM Textiles Spinners as a
                    Factory Manager. He has over 20 years of experience in
                    cotton yarn manufacturing, quality and process control in
                    textiles industry.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModalM7Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM7}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Raj Kapadia</strong>, is the President of Domestic
                    Sales of our Company. He has been associated with our
                    Company since April 1, 2015. He has passed the examination
                    for bachelor’s degree in commerce from University of Mumbai.
                    He engages with customers and manages sales across our
                    Indian customers. He has over 9 years of experience in sales
                    and marketing. He was previously associated with Universal
                    Texturisers Private Limited, our Subsidiary as Head – Sales.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* {isModalM8Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM8}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <h5>Satish Chitre</h5>
              <p>Senior Vice President – Quality {"&"} Process Control</p>
              <ul className="modal_data">
                <li>
                  <p>
                    He has been associated with the Company since June 16, 2011.
                  </p>
                </li>
                <li>
                  <p>
                    He holds a master’s degree in textile engineering from
                    Maharaja Sayajirao University of Baroda.
                  </p>
                </li>
                <li>
                  <p>
                    He has over 22 years of experience in process control,
                    quality control and product development activities in the
                    texturizing process of polyester yarn industry.
                  </p>
                </li>
                <li>
                  <p>
                    Prior to joining our Company, he was associated with Viptex
                    (a division of Vapi Paper Mills Limited) and Reliance
                    Industries Limited.{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )} */}

      {isModalM9Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM9}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              {/* <h5>Nachimuthu Senthilvel</h5>
              <p>Chief Operating Officer (Manufacturing Site)</p> */}
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Nachimuthu Senthilvel</strong>, is the Chief
                    Operating Officer – Silvassa Unit of our Company. He has
                    been associated with the Company since October 1, 2012. He
                    holds a bachelor’s degree of technology in textiles
                    technology from PSG College of Technology, Anna University
                    and a master’s degree in arts (history) from Madurai Kamaraj
                    University. He oversees plant operations for Silvassa,
                    ensuring efficient and smooth functioning of all
                    manufacturing processes and resource allocation. Prior to
                    joining our Company, he was associated with the Coimbatore
                    Pioneer Mills Limited, B.K. Polytex (division of B.K. Paper
                    Mills Limited), Vijayeswari Textiles Limited and Universal
                    Texturisers Private Limited, our Subsidiary. He has over 24
                    years of experience in textiles industry.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* {isModalM10Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM10}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <h5>Suhas Thakur</h5>
              <p>Vice President – Export Sales</p>
              <ul className="modal_data">
                <li>
                  <p>
                    He has been associated with the Company since February 1,
                    2008.
                  </p>
                </li>
                <li>
                  <p>
                    He holds a bachelor’s degree in Commerce from University of
                    Mumbai.
                  </p>
                </li>
                <li>
                  <p>
                    He has also completed the diploma in computerised financial
                    accounting.
                  </p>
                </li>
                <li>
                  <p>
                    He has over 26 years of experience in textiles industry.
                  </p>
                </li>
                <li>
                  <p>
                    Prior to joining our Company, he was associated with
                    Chhabria Polyester Corporation manging export, sales and
                    accounts.
                  </p>
                </li>
                <li>
                  <p>
                    He has also worked in Atharva Pharmaceuticals Private
                    Limited for three years in accounts department.{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )} */}

      {isModalM11Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM11}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <h5>Venkobarao Srinivas</h5>
              <p>Vice President – Engineering</p>
              <ul className="modal_data">
                <li>
                  <p>
                    He has been associated with the Company since July 16, 2018.
                  </p>
                </li>
                <li>
                  <p>
                    He holds a diploma in machine tool maintenance and repairs
                    from State Board of Technical Education and Training.
                  </p>
                </li>
                <li>
                  <p>
                    He has over 27 years of experience in engineering field and
                    in polyester plants in India and overseas.
                  </p>
                </li>
                <li>
                  <p>
                    He has also worked with organisations in polyester
                    industries like Orissa Synthetics Limited, Sanghi Polyesters
                    Limited, Modern Petrofils (a unit of Modern Syntex (I)
                    Limited), IndoKorean Petrochem Limited.
                  </p>
                </li>
                <li>
                  <p>
                    He has also worked for Karkh Water Project, Republic of
                    Iraq.{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModalM12Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM12}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              {/* <h5>Varun Dattani</h5>
              <p>Director – Export {"&"} Yarns for Technical Textiles</p> */}
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Varun Dattani</strong>, is the Director – Export and
                    Yarns for Technical Textiles and Industrial Use of our
                    Company. He has been associated with our Company since
                    January 1, 2011. He holds a bachelor’s degree in science
                    (Technical Systems Management) from University of Illinois
                    at Urbana-Champaign. His core responsibilities include
                    Business Expansion of Technical Textile division, Market
                    Analysis specially for Exports, and People Management (HR).
                    He has over 13 years of experience in textiles industry.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModalM13Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM13}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              {/* <h5>Gulvinder Singh</h5>
              <p>Vice President-Operation</p> */}
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Gulvinder Singh Aulakh </strong>, is the President –
                    Operations DTY2 of our Company. He has been associated with
                    the Company since July 12, 2021. He has completed diploma in
                    mechanical engineering from Punjab State Board of Technical
                    Education. He has passed the examination for bachelor’s
                    degree in technology (mechanical) from Karnataka State Open
                    University. His responsibilities at our Company include
                    managing our polyester division – DTY (Draw Textured Yarn).
                    Prior to joining our Company, he was previously associated
                    with Reliance Industries Limited. He has over 34 years of
                    experience in managing the plants.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isModalM14Open && (
        <div className="profiles_modal">
          <div className="profiles_modal_popup">
            <div className="close_button">
              <button type="button" onClick={toggleModalM14}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal_data">
              <ul className="modal_data">
                <li>
                  <p>
                    <strong>Kaushik Mody</strong>, is the Director of Operations
                    and Administration of our Company. He has been associated
                    with the Company since June 1, 2021. He holds a bachelor’s
                    degree in mechanical engineering and a degree in Master of
                    Business Administration from the Maharaja Sayajirao
                    University of Baroda. Prior to joining our Company, he was
                    associated with Reliance Industries Limited, Petrofils
                    Co-Operative Limited, and Silvassa Industries Private
                    Limited. He plays an important role in formulating
                    strategies for expansion of our Company’s business and
                    oversees the function of our manufacturing units. He has
                    over several years of experience in business and site
                    management.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <section className="aboutus_management" data-aos="fade-in">
        <div className="wrapper">
          <div class="breadcrumb_styles">About us {">"} Our Team</div>
          <div className="left_section">
            <h1 className="hollow_title">Our Team</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <h3 className="management_desktop_heading">
              We attribute our growth to the experience of our Promoters and
              senior management team. Our Promoters have cumulative experience
              of approximately 160 years in the textiles industry with more than
              48 years in the yarn business. Our Board of Directors are ably
              supported by a qualified and experienced team of Key Managerial
              Personnel and members of Senior Management.
            </h3>
            <h3 className="management_mobile_heading">
              We attribute our growth to the experience of our Promoters and
              senior management team. Our Promoters have cumulative experience
              of approximately 160 years in the textiles industry with more than
              48 years in the yarn business. Our Board of Directors are ably
              supported by a qualified and experienced team of Key Managerial
              Personnel and members of Senior Management.
            </h3>
            {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p> */}
          </div>
          {/* <div className="right_section">
                        <figure className="bg_img">
                            <img src={BannerRight} alt="sanathan img" />
                        </figure>
                    </div> */}
        </div>
      </section>

      <section className="board_of_directors">
        <div className="wrapper">
          <div className="hollow_title_wrapper">
            <div className="hollow_title">Board of Directors</div>
          </div>
          <div className="board_of_directors_container board_of_directors_container_desktop">
            <ul className="board_of_directors_profiles">
              <li>
                <div className="profile" onClick={toggleModal1}>
                  <figure>
                    <img src={BOD1} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Paresh V. Dattani</h6>
                    <p>Chairman and Managing Director</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="profile" onClick={toggleModal2}>
                  <figure>
                    <img src={BOD2} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Ajay V. Dattani</h6>
                    <p>Joint Managing Director</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="profile" onClick={toggleModal3}>
                  <figure>
                    <img src={BOD3} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Dinesh Vrajdas Dattani</h6>
                    <p>Executive Director</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="profile" onClick={toggleModal4}>
                  <figure>
                    <img src={BOD4} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Anilkumar Vrajdas Dattani</h6>
                    <p>Executive Director</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="profile" onClick={toggleModal5}>
                  <figure>
                    <img src={BOD5} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Debabrata Sarkar</h6>
                    <p>Independent Director</p>
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="profile" onClick={toggleModal6}>
                  <figure>
                    <img src={BOD6} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Bhumika Batra</h6>
                    <p>Independent Director</p>
                  </div>
                </div>
              </li> */}
              <li>
                <div className="profile" onClick={toggleModal7}>
                  <figure>
                    <img src={BOD8} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Khurshed Thanawalla</h6>
                    <p>Independent Director</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="profile" onClick={toggleModal8}>
                  <figure>
                    <img src={BOD7} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Vinay Aggarwal</h6>
                    <p>Independent Director</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="profile" onClick={toggleModal9}>
                  <figure>
                    <img src={BOD9} alt="Rupal Vora" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Rupal Vora</h6>
                    <p>Independent Director</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="board_of_directors_container board_of_directors_container_mobile swiper-container"
            data-aos="fade-up"
          >
            <ul className="board_of_directors_profiles swiper-wrapper">
              <li className="swiper-slide">
                <div className="profile" onClick={toggleModal1}>
                  <figure>
                    <img src={BOD1} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Paresh V. Dattani</h6>
                    <p>Chairman and Managing Director</p>
                  </div>
                </div>
              </li>
              <li className="swiper-slide">
                <div className="profile" onClick={toggleModal2}>
                  <figure>
                    <img src={BOD2} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Ajay V. Dattani</h6>
                    <p>Joint Managing Director</p>
                  </div>
                </div>
              </li>
              <li className="swiper-slide">
                <div className="profile" onClick={toggleModal3}>
                  <figure>
                    <img src={BOD3} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Dinesh Vrajdas Dattani</h6>
                    <p>Executive Director</p>
                  </div>
                </div>
              </li>
              <li className="swiper-slide">
                <div className="profile" onClick={toggleModal4}>
                  <figure>
                    <img src={BOD4} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Anilkumar Vrajdas Dattani </h6>
                    <p>Executive Director</p>
                  </div>
                </div>
              </li>
              <li className="swiper-slide">
                <div className="profile" onClick={toggleModal5}>
                  <figure>
                    <img src={BOD5} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Debabrata Sarkar</h6>
                    <p>Independent Director</p>
                  </div>
                </div>
              </li>
              {/* <li className="swiper-slide">
                <div className="profile" onClick={toggleModal6}>
                  <figure>
                    <img src={BOD6} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Bhumika Batra</h6>
                    <p>Independent Director</p>
                  </div>
                </div>
              </li> */}
              <li className="swiper-slide">
                <div className="profile" onClick={toggleModal7}>
                  <figure>
                    <img src={BOD8} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Khurshed Thanawalla</h6>
                    <p>Independent Director</p>
                  </div>
                </div>
              </li>
              <li className="swiper-slide">
                <div className="profile" onClick={toggleModal8}>
                  <figure>
                    <img src={BOD7} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Vinay Aggarwal</h6>
                    <p>Independent Director</p>
                  </div>
                </div>
              </li>
              <li className="swiper-slide">
                <div className="profile" onClick={toggleModal9}>
                  <figure>
                    <img src={BOD9} alt="sanathan img" />
                  </figure>
                  <div className="name_and_designation">
                    <h6>Rupal Vora</h6>
                    <p>Independent Director</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="management_team">
        <div className="wrapper">
          <div className="hollow_title">Our Management</div>
          <div
            className="management_team_thumb_container swiper-container"
            data-aos="fade-up"
          >
            <ul className="swiper-wrapper">
              <li className="swiper-slide" onClick={toggleModalM4}>
                <figure>
                  <img src={M4} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Mikesh Dattani</h5>
                  <p>Director – Production Planning</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM12}>
                <figure>
                  <img src={M12} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Varun Dattani</h5>
                  <p>Director – Export {"&"} Yarns for Technical Textiles</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM5}>
                <figure>
                  <img src={M15} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Sanjay Anirudh Shah</h5>
                  <p>Chief Financial Officer</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM3}>
                <figure>
                  <img src={M3} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Jude Patrick Dsouza</h5>
                  <p>Company Secretary and Compliance Officer</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM1}>
                <figure>
                  <img src={deepakPrasad} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Deepak Prasad</h5>
                  <p>Chief Safety {"&"} Security Officer</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM6}>
                <figure>
                  <img src={M6} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Mandiramoorthy Palanisamy</h5>
                  <p>President – Cotton Division</p>
                </div>
              </li>
              {/* <li className="swiper-slide" onClick={toggleModalM8}>
                <figure>
                  <img src={satishChitre} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Satish Chitre</h5>
                  <p>Senior Vice President – Quality {"&"} Process Control</p>
                </div>
              </li> */}
            </ul>
          </div>
          <div
            className="management_team_container swiper-container"
            data-aos="fade-up"
          >
            <ul className="swiper-wrapper">
              <li className="swiper-slide" onClick={toggleModalM14}>
                <figure>
                  <img src={kaushikMody} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Kaushik Mody</h5>
                  <p>Director - Operations {"&"} Administrations</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM7}>
                <figure>
                  <img src={M7} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Raj Kapadia</h5>
                  <p>President – Domestic Sales</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM9}>
                <figure>
                  <img src={senthilvel} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Nachimuthu Senthilvel</h5>
                  <p>Chief Operating Officer (Manufacturing Site)</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM2}>
                <figure>
                  <img src={M2} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Beena Paresh Dattani</h5>
                  <p>Director – Social Welfare</p>
                </div>
              </li>
              {/* <li className="swiper-slide" onClick={toggleModalM11}>
                <figure>
                  <img src={venkobaraoSrinivas} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Venkobarao Srinivas</h5>
                  <p>Vice President – Engineering</p>
                </div>
              </li> */}
              <li className="swiper-slide" onClick={toggleModalM13}>
                <figure>
                  <img src={gulvinder} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Gulvinder Singh Aulakh</h5>
                  <p>President - Operations DTY2</p>
                </div>
              </li>
              {/* <li className="swiper-slide" onClick={toggleModalM10}>
                <figure>
                  <img src={M10} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Suhas Thakur</h5>
                  <p>Vice President – Export Sales</p>
                </div>
              </li> */}
              <li className="swiper-slide"></li>
            </ul>
          </div>
          <div
            className="management_team_container_mobile swiper-container"
            data-aos="fade-up"
          >
            <ul className="swiper-wrapper">
              <li className="swiper-slide" onClick={toggleModalM4}>
                <figure>
                  <img src={M4} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Mikesh Dattani</h5>
                  <p>Director – Production Planning</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM12}>
                <figure>
                  <img src={M12} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Varun Dattani</h5>
                  <p>Director – Export {"&"} Yarns for Technical Textiles</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM5}>
                <figure>
                  <img src={M15} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Sanjay Anirudh Shah</h5>
                  <p>Chief Financial Officer</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM3}>
                <figure>
                  <img src={M3} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Jude Patrick Dsouza</h5>
                  <p>Company Secretary and Compliance Officer</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM12}>
                <figure>
                  <img src={kaushikMody} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Kaushik Mody</h5>
                  <p>Director - Operations {"&"} Administrations</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM7}>
                <figure>
                  <img src={M7} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Raj Kapadia</h5>
                  <p>President – Domestic Sales</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM9}>
                <figure>
                  <img src={senthilvel} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Nachimuthu Senthilvel</h5>
                  <p>Chief Operating Officer (Manufacturing Site)</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM2}>
                <figure>
                  <img src={M2} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Beena Paresh Dattani</h5>
                  <p>Director – Social Welfare</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM1}>
                <figure>
                  <img src={deepakPrasad} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Deepak Prasad</h5>
                  <p>Chief Safety {"&"} Security Officer</p>
                </div>
              </li>
              {/* <li className="swiper-slide" onClick={toggleModalM11}>
                <figure>
                  <img src={venkobaraoSrinivas} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Venkobarao Srinivas</h5>
                  <p>Vice President – Engineering</p>
                </div>
              </li> */}
              <li className="swiper-slide" onClick={toggleModalM6}>
                <figure>
                  <img src={M6} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Mandiramoorthy Palanisamy</h5>
                  <p>President – Cotton Division</p>
                </div>
              </li>
              <li className="swiper-slide" onClick={toggleModalM12}>
                <figure>
                  <img src={gulvinder} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Gulvinder Singh Aulakh</h5>
                  <p>President - Operations DTY2</p>
                </div>
              </li>
              {/* <li className="swiper-slide" onClick={toggleModalM8}>
                <figure>
                  <img src={satishChitre} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Satish Chitre</h5>
                  <p>Senior Vice President – Quality {"&"} Process Control</p>
                </div>
              </li> */}
              {/* <li className="swiper-slide" onClick={toggleModalM10}>
                <figure>
                  <img src={M10} alt="sanathan img" />
                </figure>
                <div className="name_and_designation">
                  <h5>Suhas Thakur</h5>
                  <p>Vice President – Export Sales</p>
                </div>
              </li> */}
            </ul>
          </div>
          <div className="navigations">
            <button className="left_arrow">Previous</button>
            <button className="right_arrow">Next</button>
          </div>
        </div>
      </section>

      {isModalCareersOpen && <Careers onRequestClose={toggleCareersModal} />}
      <section className="join_us">
        <div className="wrapper">
          <figure data-aos="fade-up">
            <img src={JoinUs} alt="sanathan img" />
          </figure>
          <div className="content" data-aos="fade-up">
            <div className="hollow_title">Join us</div>
            {/* <h3>Teamwork is the ability <br /> work together toward a <br /> common vision</h3> */}
            <p>
              At Sanathan Textiles, we build careers by investing in you. We
              strive to create an environment for your professional development
              and personal growth, as we believe your success is our success.
            </p>
            <div className="bottom_arrow_container">
              {/* <Link onClick={toggleCareersModal}>Apply Now</Link> */}
              <button
                className="download_btn_text text-white"
                onClick={toggleCareersModal}
                type="button"
              >
                Apply Now
                <span className="arrow">
                  <img src={whiteArrow} alt="sanathan img" />
                </span>
              </button>
            </div>
            {/* <div className="link_wrapper"> */}
            {/* <Link to="#">JOIN THE PEOPLE</Link> */}
            {/* <span className="arrow"><img src={whiteArrow} alt="sanathan img" /></span> */}
            {/* </div> */}
          </div>
        </div>
      </section>
      <section className="join_us_mobile">
        <div className="wrapper">
          <figure data-aos="fade-up">
            <img src={JoinUsMobile} alt="sanathan img" />
          </figure>
          <div className="content" data-aos="fade-up">
            <div className="hollow_title">Join us</div>
            <p>
              At Sanathan Textiles, we build careers by investing in you. We
              strive to create an environment for your professional development
              and personal growth, as we believe your success is our success.
            </p>
            <div className="bottom_arrow_container">
              <button
                className="download_btn_text text-white"
                onClick={toggleCareersModal}
                type="button"
              >
                Apply Now
                <span className="arrow">
                  <img src={whiteArrow} alt="sanathan img" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsManagement;
