import { useEffect, useState } from "react";
import "../ProductsInner.scss";
import "../style.scss";
import "./borndyed.scss";

import {
  coloredArrow,
  Borndyed1,
  Borndyed2,
  Borndyed3,
  Borndyed4,
  Borndyed5,
  Borndyed6,
  ytthumb,
} from "../../../images";
import Dash from "../../../components/Dash/Dash";
import ProductsPopup from "../../../components/ProductsPopup/ProductsPopup";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const Borndyed = () => {
  const [isModalOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/products/born-dyed"
        metaTitle="Dyed Polyester & Coloured Yarns - Dyed Yarn Manufacturer | Sanathan Textiles"
        metaDesc="Explore BornDyed, multicoloured yarn from a top-dyed yarn manufacturer in India. Our dyed polyester & coloured yarns at Sanathan Textiles offer premium quality for various applications."
      />

      {isModalOpen && <ProductsPopup onRequestClose={toggleModal} />}
      <section className="products_inner selfpadding">
        <div className="wrapper">
          <div class="breadcrumb_styles">Products {">"} Born Dyed</div>
          <div className="left_section">
            <div className="hollow_title">Products</div>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <h3>Born Dyed</h3>
            <p>
              Our range of polyester coloured yarns - ‘BornDyed’ are made using
              the dope dyed technology where colour pigment is added to the
              yarns at the polymerisation stage.
            </p>
            <img src={coloredArrow} className="arrow" alt="sanathan img" />
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={Borndyed1} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="products_inner_watch_now">
        <div className="wrapper">
          <div className="hollow_title">Watch Now</div>
          <figure className="for_desk">
            {/* <img src="https://dummyimage.com/1640x680/000/000000" alt="sanathan img"/> */}
            <iframe
              width="100%"
              height="680"
              src="https://www.youtube.com/embed/6yOKicpWozc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </figure>
          <figure className="for_mob">
            {/* <img src="https://dummyimage.com/1640x680/000/000000" alt="sanathan img"/> */}
            <iframe
              width="100%"
              height="220"
              src="https://www.youtube.com/embed/6yOKicpWozc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </figure>
        </div>
      </section>
      <section className="products_inner_list">
        <div className="wrapper">
          <div className="products_inner_list_heading_wrapper">
            <div className="hollow_title">Advantages</div>
            {/* <h3>
              Click on any product to view <br /> detailed information
            </h3> */}
          </div>
          <div className="products_inner_listing">
            <div className="product_box">
              <figure className="small_icon">
                <img src={Borndyed3} alt="sanathan img" />
              </figure>
              <div className="bornimg_head">Eco-Friendly</div>
              <div className="bornimg_desc">
                Born Dyed Yarns significantly reduce water pollution.
              </div>
              {/* <button onClick={toggleModal}>Partially Oriented Yarn</button> */}
            </div>
            <div className="product_box">
              <figure className="small_icon">
                <img src={Borndyed4} alt="sanathan img" />
              </figure>
              <div className="bornimg_head">Efficient Process</div>
              <div className="bornimg_desc">
                Born Dyed Yarns eliminates the process of conventional dying. It
                can directly go for knitting & weaving of fabrics.
              </div>
              {/* <button onClick={toggleModal}>Fully Drawn Yarn</button> */}
            </div>
            <div className="product_box">
              <figure className="small_icon">
                <img src={Borndyed5} alt="sanathan img" />
              </figure>
              <div className="bornimg_head">Excellent Quality</div>
              <div className="bornimg_desc">
                The Yarns are dyed by adding pigments at the polymerization
                stage giving them excellent quality & colour fastness.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="born_sec3">
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-6 first">
              <img src={Borndyed6} alt="sanathan img" />
            </div>
            <div className="col-lg-6 second_section">
              <h1 className="hollow_title">Our Yarns Your Colour</h1>
              <p>
                BornDyed™ range of coloured polyester yarns is customised to the
                exact shade and tone required by our customers. We offer Made to
                order (MTO) shades specially developed to meet the customer’s
                requirements. <br />
                <br />
                We make these yarns in Partially Oriented Yarn, Fully Drawn
                Yarns, Texturised Yarns and High Tenacity Yarns.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Borndyed;
